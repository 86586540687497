// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const { useState } = React;

const { Card } = require('@andes/card');

const Actions = require('./actions');
const Feedback = require('./feedback');
const LoggedForm = require('./logged-form');
const UnloggedForm = require('./unlogged-form');

const Form = ({
  user = {
    email: '',
    phone: '',
    fullName: '',
    hasLeader: false,
    userId: 0,
    isReseller: false,
  },
  traficOrigin = '',
}) => {
  const [apiResponseType, setApiResponseType] = useState('');
  const [errors, setErrors] = useState([]);

  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState(user?.fullName || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [province, setProvince] = useState(user?.phone || '');

  const hasLoginEmpty = !user?.email;
  const hasRgrParam = traficOrigin?.includes('utm_source=RGR');
  const hasLeader = hasRgrParam && user?.hasLeader;

  const encodedURL = encodeURIComponent(
    `https://www.mercadopago.com.ar/revendedores?${traficOrigin?.replace('&', '')}`,
  );

  const userData = {
    email,
    hasLeader,
    logged: !!user?.email,
    name,
    phone,
    province,
    userId: user?.userId,
    isReseller: user?.isReseller,
  };

  const formComponent = !hasLoginEmpty ? (
    <LoggedForm
      callbackUrl={encodedURL}
      email={email}
      hasLeader={hasLeader}
      name={user?.fullName?.split(' ')[0] || user?.fullName}
    />
  ) : (
    <UnloggedForm
      errors={errors}
      hasRGR={hasRgrParam}
      province={province}
      onSetEmail={setEmail}
      onSetErrors={setErrors}
      onSetName={setName}
      onSetPhone={setPhone}
      onSetProvince={setProvince}
    />
  );

  return (
    <Card
      className="form__card"
      data-feedback={!!apiResponseType}
      paddingsize={32}
      shadow={apiResponseType ? 'elevated' : ''}
    >
      {apiResponseType ? (
        <Feedback
          email={email}
          name={name?.split(' ')[0] || name}
          traficOrigin={traficOrigin}
          type={apiResponseType}
        />
      ) : (
        formComponent
      )}
      <Actions
        apiResponseType={apiResponseType}
        errors={errors}
        traficOrigin={traficOrigin}
        user={userData}
        onSetApiResponseType={setApiResponseType}
      />
    </Card>
  );
};

Form.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    hasLeader: PropTypes.bool,
    userId: PropTypes.number,
    isReseller: PropTypes.bool,
  }),
  traficOrigin: PropTypes.string,
};

module.exports = Form;
