const validators = require('./validators');

const helperForEmailField = (value = '') => {
  const hasDotAndAt = validators.validateHasDotAndAt(value);

  if (value.length === 0) {
    return 'Ingresa tu e-mail.';
  }

  if (hasDotAndAt) {
    return 'E-mail inválido.';
  }

  return 'E-mail incompleto.';
};

const helperForNameField = (value = '') => {
  const hasOnlyLetters = validators.validateOnlyLetters(value);

  if (value.length === 0) {
    return 'Ingresa tu nombre.';
  }

  if (!hasOnlyLetters) {
    return 'Solo se permiten letras.';
  }

  return 'Nombre incompleto.';
};

const helperForPhoneField = (value = '') => {
  if (value.length === 0) {
    return 'Ingresa tu número.';
  }

  return 'Número incompleto.';
};

module.exports = {
  helperForEmailField,
  helperForNameField,
  helperForPhoneField,
};
