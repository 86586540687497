// i18nLocalesOverride:['es-CL']

const React = require('react');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { Text, Title } = require('@andes/typography');

const Form = require('@components/form');

const BASE_CLASS_NAME = 'hero';

const Hero = () => {
  const { i18n } = useI18n();

  const { assetsPath, isInRebrand } = useRebrandContext();

  const { isMobile, isWebview, traficOrigin, siteId, userData } = useLandingContext();

  const imageName = isMobile ? 'hero-bg-mobile' : 'hero-bg-desktop';
  const textColor = isInRebrand ? 'primary' : 'inverted';

  return (
    <div
      className={BASE_CLASS_NAME}
      style={{ backgroundColor: isInRebrand ? '#ffe600' : '#0b105b' }}
    >
      <div className={`${BASE_CLASS_NAME}__container`}>
        <section className={`${BASE_CLASS_NAME}__title`}>
          <Text color={textColor} size={isMobile ? 'xs' : 's'} weight="semibold">
            {i18n.gettext('Programa de revendedores')}
          </Text>
          <Title color={textColor} component="h1" size="xl">
            {i18n.gettext('Gana dinero al vender lectores de tarjetas')}
          </Title>
        </section>
        <section className={`${BASE_CLASS_NAME}__form`}>
          <Form isWebview={isWebview} siteId={siteId} traficOrigin={traficOrigin} user={userData} />
        </section>
        <Image
          preload
          alt="mercado-pago-point-hero"
          lazyload="off"
          src={`${assetsPath}/${imageName}.webp`}
          srcFallback={`${assetsPath}/${imageName}.png`}
        />
      </div>
    </div>
  );
};

module.exports = Hero;
