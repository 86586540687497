// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useCallback, useEffect, useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { Button } = require('@andes/button');

const tracking = require('@utils/tracking');

const { MICROSITIO_URL } = require('@domain/constants/external-urls');

const BASE_CLASS_NAME = 'header-fixed';

const HeaderFixed = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { isMobile, siteId, userData } = useLandingContext();
  const { assetsPath, isInRebrand } = useRebrandContext();
  const { i18n } = useI18n();

  const isReseller = userData?.isReseller;

  const handleClick = () => {
    if (isReseller) {
      tracking('home', 'button-header-fixed-go-to-portal');

      window.location.href = MICROSITIO_URL[siteId];

      return;
    }

    tracking('home', 'button-header-fixed');

    window.scrollTo(0, 0);
  };

  const handleScroll = useCallback(() => {
    const threshold = isMobile ? 1340 : 860;

    const { scrollY } = window;

    setIsVisible(scrollY > threshold);
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      className={`${BASE_CLASS_NAME} ${isInRebrand ? '' : `${BASE_CLASS_NAME}--blue`}`}
      data-visible={isVisible}
    >
      <section>
        <Image
          height={isMobile ? 26 : 38}
          src={
            isMobile
              ? `${assetsPath}/header-mp-mobile.webp`
              : `${assetsPath}/header-mp-desktop.webp`
          }
          srcFallback={
            isMobile ? `${assetsPath}/header-mp-mobile.png` : `${assetsPath}/header-mp-desktop.png`
          }
          width="auto"
        />
        <Button hierarchy="loud" onClick={() => handleClick()}>
          {isReseller ? i18n.gettext('Ir al portal') : i18n.gettext('Ingresar al programa')}
        </Button>
      </section>
    </div>
  );
};

module.exports = HeaderFixed;
