// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useCallback, useRef } = React;

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { Form } = require('@andes/form');
const { Text } = require('@andes/typography');
const { TextField } = require('@andes/textfield');

const Recaptcha = require('../../../recaptcha');
const Tyc = require('../tyc');

const helperText = require('../../utils/field-helpers');
const masks = require('@andes/textfield/build/masks');
const tracking = require('@utils/tracking');
const useMask = require('@andes/textfield/build/hooks/useMask');
const validators = require('../../utils/validators');

const RECAPTCHA_LANGUAGE_ID = require('../../constants/recaptcha-language-id');

const BASE_CLASS_NAME = 'unlogged-user';

const MASKS_BY_SITE_ID = {
  MLB: masks.BR.MOBILE,
  MLC: masks.CL.MOBILE,
};

const UnloggedUser = () => {
  const { i18n } = useI18n();

  const {
    errors = [],
    onSetEmail,
    onSetErrors,
    onSetName,
    onSetPhone,
    onSetTyc,
    recaptchaError,
    showErrors,
    siteId,
    user,
    validateForm,
  } = useFormContext();

  const hasMailError = showErrors && errors?.includes('MAIL');
  const hasNameError = showErrors && errors?.includes('NAME');
  const hasPhoneError = showErrors && errors?.includes('PHONE');
  const hasRecaptchaError = showErrors && recaptchaError;

  const phoneFieldRef = useRef();

  useMask(phoneFieldRef, { maskOptions: MASKS_BY_SITE_ID[siteId] });

  const fieldInteractionTracking = (field) => tracking('home', `form-${field}-interaction`);

  const handleError = useCallback(
    (type = 'add', key) => {
      let newErrors = errors;

      if (type === 'remove') {
        newErrors = errors.length ? errors.filter((er) => er !== key) : [];
      } else {
        newErrors.push(key);
      }

      onSetErrors(newErrors);
    },
    [errors, onSetErrors],
  );

  const validateEmail = useCallback(
    (event) => {
      const { value } = event.target;

      const validEmail = validators.validateEmail(value);

      handleError(validEmail ? 'remove' : 'add', 'MAIL');

      onSetEmail(value);
    },
    [handleError, onSetEmail],
  );

  const validateName = useCallback(
    (event) => {
      const { value } = event.target;

      const isValid = validators.validateName(value, siteId);

      handleError(isValid ? 'remove' : 'add', 'NAME');

      onSetName(value);
    },
    [handleError, onSetName, siteId],
  );

  const validatePhone = useCallback(
    (event) => {
      const { value } = event.target;

      const isValid = validators.validatePhone(value, siteId);

      handleError(isValid ? 'remove' : 'add', 'PHONE');

      onSetPhone(value);
    },
    [handleError, onSetPhone, siteId],
  );

  const handleChangeTyc = () => {
    handleError('remove', 'TYC');

    onSetTyc((state) => !state);

    tracking('home', 'click-on-terms-and-conditions');
  };

  const submit = async (e) => {
    tracking('home', 'form-register-i-want-to-be-a-reseller');

    validateForm(e);
  };

  const renderContentBySiteId = () => {
    switch (siteId) {
      case 'MLB':
        return {
          actionText: i18n.gettext('Iniciar registro'),
          title: i18n.gettext('Ingresa tus datos para iniciar tu registro'),
          fields: {
            name: {
              helper: hasNameError ? i18n.gettext(helperText.helperForNameField(user.name)) : '',
              label: i18n.gettext('Nombre completo'),
              placeholder: 'João dos Santos',
              srLabel: i18n.gettext('Nombre completo'),
            },
            email: {
              helper: hasMailError ? i18n.gettext(helperText.helperForEmailField(user.email)) : '',
              label: i18n.gettext('E-mail'),
              placeholder: 'joaosantos@gmail.com',
              srLabel: i18n.gettext('E-mail'),
            },
            phone: {
              prefix: '+55',
              helper: hasPhoneError ? i18n.gettext(helperText.helperForPhoneField(user.phone)) : '',
              label: i18n.gettext('Número de WhatsApp'),
              placeholder: '(99) 99999-9999',
              srLabel: i18n.gettext('Número de WhatsApp'),
            },
          },
          recaptchaErrorMessage: i18n.gettext('Es obligatorio rellenar este campo para avanzar.'),
        };

      case 'MLC':
      default:
        return {
          actionText: i18n.gettext('Registrarse'),
          title: i18n.gettext('Regístrate en el programa'),
          fields: {
            name: {
              helper: hasNameError ? i18n.gettext(helperText.helperForNameField(user.name)) : '',
              label: i18n.gettext('Nombre completo'),
              placeholder: 'Sofía Gonzáles Muñoz',
              srLabel: i18n.gettext('Nombre completo'),
            },
            email: {
              helper: hasMailError ? i18n.gettext(helperText.helperForEmailField(user.email)) : '',
              label: i18n.gettext('E-mail'),
              placeholder: 'sofiamuñoz@gmail.com',
              srLabel: i18n.gettext('E-mail'),
            },
          },
          recaptchaErrorMessage: i18n.gettext('Marca este campo para continuar.'),
        };
    }
  };

  const { actionText, fields, recaptchaErrorMessage, title } = renderContentBySiteId();

  return (
    <Card className={BASE_CLASS_NAME}>
      <Text component="h2" weight="semibold">
        {title}
      </Text>

      <Form onSubmit={submit}>
        <div className={`${BASE_CLASS_NAME}__fields`} data-site-id={siteId}>
          <TextField
            className={`${BASE_CLASS_NAME}__field-name`}
            defaultValue={user.name}
            helper={fields.name.helper}
            label={fields.name.label}
            modifier={hasNameError ? 'error' : undefined}
            placeholder={fields.name.placeholder}
            srLabel={fields.name.srLabel}
            onBlur={(target) => {
              if (target.value) {
                fieldInteractionTracking('name');
              }
            }}
            onChange={validateName}
          />

          {fields.phone && (
            <TextField
              ref={phoneFieldRef}
              className={`${BASE_CLASS_NAME}__field-phone`}
              defaultValue={user.phone}
              helper={fields.phone.helper}
              label={fields.phone.label}
              modifier={hasPhoneError ? 'error' : undefined}
              placeholder={fields.phone.placeholder}
              prefix={fields.phone.prefix}
              srLabel={fields.phone.srLabel}
              onBlur={({ target }) => {
                if (target.value) {
                  fieldInteractionTracking('phone');
                }
              }}
              onChange={validatePhone}
            />
          )}

          <TextField
            className={`${BASE_CLASS_NAME}__field-email`}
            defaultValue={user.email}
            helper={fields.email.helper}
            label={fields.email.label}
            modifier={hasMailError ? 'error' : undefined}
            placeholder={fields.email.placeholder}
            srLabel={fields.email.srLabel}
            onBlur={(target) => {
              if (target.value) {
                fieldInteractionTracking('email');
              }
            }}
            onChange={validateEmail}
          />
        </div>

        <Recaptcha
          errors={hasRecaptchaError ? [recaptchaErrorMessage] : []}
          languageId={RECAPTCHA_LANGUAGE_ID[siteId]}
        />

        <Tyc isChecked={user.tyc} onHandleChange={handleChangeTyc} />

        <Button fullWidth type="submit">
          {actionText}
        </Button>
      </Form>
    </Card>
  );
};

module.exports = UnloggedUser;
