const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');
const { Title } = require('@andes/typography');
const ChevronUp16 = require('@andes/icons/ChevronUp16');

const tracking = require('@utils/tracking');

const FooterComponent = ({ isMobile, siteId }) => {
  const isMLM = siteId === 'MLM';

  const handleScroll = (position) => window?.scrollTo(0, position);

  const svgIcon = (
    <svg
      fill="none"
      height="41"
      viewBox="0 0 154 41"
      width="154"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.9525 0C42.7661 0 53.0044 40.9851 0.0917969 40.9851H153.908C104.071 40.9851 113.139 0 77.9525 0Z"
        fill="#068EE4"
      />
    </svg>
  );

  return (
    <div className={`footer ${siteId?.toLowerCase()}`}>
      <div
        className="go-to-top"
        role="button"
        tabIndex={-1}
        onClick={() => {
          tracking('home', 'footer-scroll-to-top');
          handleScroll(0);
        }}
        onKeyDown={() => {}}
      >
        <ChevronUp16 className="chevron" color="#ffffff" />
        {svgIcon}
      </div>
      <div className="footer-banner">
        <Title component="h3">DE AHORA EN ADELANTE, MERCADO PAGO.</Title>
        <Title size="xs">
          {isMLM
            ? 'Únete al programa que te ayudará a hacer crecer tu inversión y tus ganancias.'
            : 'Unite al programa que te ayudará a hacer crecer tu inversión y tus ganancias.'}
        </Title>
        <Button
          hierarchy="loud"
          onClick={() => {
            tracking('click', { page_resource: 'landing/footer-button' });
            handleScroll(isMobile ? 750 : 550);
          }}
        >
          {isMLM ? 'Quiero ser representante' : 'Quiero ser revendedor'}
        </Button>
      </div>

      <div className="nav-footer-address">
        <p className="text">
          <small>
            {isMLM
              ? 'Av de los Insurgentes Sur 1602, Crédito Constructor, Benito Juárez, 03940 Ciudad de México, CDMX.'
              : 'Arias 3751, 7, Ciudad Autónoma de Buenos Aires (1430), Argentina'}
          </small>
        </p>
      </div>
    </div>
  );
};

FooterComponent.propTypes = {
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = FooterComponent;
