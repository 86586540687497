const React = require('react');
const PropTypes = require('prop-types');

const ResellersProgram32 = ({ color, height = 32, width = 32 }) => (
  <svg fill={color} height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M8.931 9.062a7.063 7.063 0 0 1 14.057-1h2.858v2H8.931v-1Zm12.028-1a5.065 5.065 0 0 0-9.929 0h9.93Zm-12.022 3.88a7.063 7.063 0 1 0 14.126 0h-2a5.063 5.063 0 1 1-10.126 0h-2Zm14.348 5.795 4.158 4.257H15.999v2h11.378l-4.105 4.359 1.456 1.37 6.411-6.807-6.424-6.576-1.43 1.397Zm-19.3 9.845a3.577 3.577 0 0 1 3.576-3.578H12v-2H7.56a5.577 5.577 0 0 0-5.577 5.577v.731h2v-.73Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

ResellersProgram32.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

module.exports = ResellersProgram32;
