const React = require('react');
const PropTypes = require('prop-types');

const { Title, Text } = require('@andes/typography');

const CLASS_BY_TYPE = {
  benefit: 'benefit-item',
  discovery: 'discover-item',
  level: 'level-item',
  point: 'point-item',
};

const ItemDetails = (props) => {
  const {
    title,
    description,
    secondDescription,
    icon,
    className,
    type = 'discovery',
    bullets,
    hasBullet,
  } = props;

  return (
    <div className={`${CLASS_BY_TYPE[type || 'discovery']} ${className || ''}`}>
      {icon && <div>{icon}</div>}
      <div>
        {title && (
          <Title className="bold" size="xs" type="title">
            {title}
          </Title>
        )}
        {description && (
          <Text className={type === 'level' && hasBullet ? 'bullet' : ''}>
            {type === 'level' && hasBullet && <span />}
            {description}
          </Text>
        )}
        {secondDescription && <Text className="secondary-description">{secondDescription}</Text>}
        {bullets?.length > 0 &&
          bullets.map((item) => (
            <>
              <Text key={item.text} className="bullet-item">
                {item.text}
                {item?.link && <a href={item.link.url}>{item.link.text}</a>}
                {item?.secondaryText && item.secondaryText}
              </Text>
              {}
            </>
          ))}
      </div>
    </div>
  );
};

ItemDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  secondDescription: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
  hasBullet: PropTypes.bool,
  type: PropTypes.oneOf(['discovery', 'benefit', 'level', 'point']),
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  ),
};

module.exports = ItemDetails;
