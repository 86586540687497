const GROUP_ID = {
  MLA: {
    APRENDIZ_WITHOUT_PURCHASES: 200,
    APRENDIZ_WITH_PURCHASES: 210,
    EMPRENDEDOR: 211,
    EXPERTO: 212,
    PRE_CONSULTOR: 213,
    CONSULTOR: 214,
  },
  MLB: {
    APRENDIZ_WITHOUT_PURCHASES: 124,
    APRENDIZ_WITH_PURCHASES: 125,
    ESPECIALISTA: 101,
    EMPREENDEDOR: 102,
    TOP_EMPREENDEDOR: 107,
    LIDER: 103,
  },
  MLC: {
    EMPRENDEDOR_WITHOUT_PURCHASES: 400,
    EMPRENDEDOR_WITH_PURCHASES: 401,
    PROFESIONAL: 402,
    EXPERTO: 403, // This group does not exist in the admin
  },
  MLM: {
    APRENDIZ_WITHOUT_PURCHASES: 300,
    APRENDIZ_WITH_PURCHASES: 310,
    AFICIONADO: 301,
    PROFESIONAL: 302,
    EXPERTO: 303,
  },
};

module.exports = GROUP_ID;
