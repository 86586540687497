const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');

const { formatterPrice } = require('@utils/formatter');

const DeviceCard = ({ device = {}, siteId = 'MLA' }) => {
  const discount = device?.discount && device?.discount?.toFixed(0);

  const isMLM = siteId === 'MLM';

  const fakePrice =
    device?.fake_price &&
    formatterPrice(isMLM ? device?.price_with_discount || 0 : device?.fake_price || 0, siteId);

  const profit =
    (device?.price_with_discount && device?.suggested_retail_price) ||
    (device?.fake_price &&
      formatterPrice(
        (device?.suggested_retail_price || device?.fake_price || 0) -
          (device?.price_with_discount || 0),
        siteId,
      ));

  const suggestedPrice =
    device?.suggested_retail_price && formatterPrice(device?.suggested_retail_price || 0, siteId);

  return (
    <div className="point-discount-item">
      <Pill color="green" hierarchy="loud" roundedCorners={['bottom-left']}>
        {discount}% OFF
      </Pill>
      <div className="point-discount-item__image">
        {device?.picture_url && <Image height={128} src={device.picture_url} width={120} />}
      </div>
      <div className="point-discount-item__texts">
        <Title className="bold center" size={siteId !== 'MLA' ? 'xs' : 'm'}>
          {device?.model}
        </Title>
        {isMLM ? (
          <>
            <Text className="desc__price center" data-country="MX">
              Cómprala a {fakePrice}
            </Text>
            <Text className="desc center" data-country="MX" size="s">
              Véndela a {suggestedPrice}
            </Text>
          </>
        ) : (
          <>
            <Text className="desc__price center">
              {fakePrice}
              <Text size="xs">Precio sugerido de venta</Text>
            </Text>
            <Text className="desc center" size="s">
              Ganá {profit}
            </Text>
          </>
        )}
      </div>
    </div>
  );
};

DeviceCard.propTypes = {
  device: PropTypes.shape({
    discount: PropTypes.number,
    picture_url: PropTypes.string,
    model: PropTypes.string,
    fake_price: PropTypes.number,
    price: PropTypes.number,
    price_with_discount: PropTypes.number,
    suggested_retail_price: PropTypes.number,
  }),
  siteId: PropTypes.string,
};

module.exports = DeviceCard;
