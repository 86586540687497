// i18nLocalesOverride:['pt-BR']

const DEVICES = require('@domain/constants/devices');
const GROUP_ID = require('@domain/constants/group-id');

module.exports = (i18n, configs) => {
  const { devices: devicesConfigs, prizesConfigs } = configs;

  let devicesLabel = '';

  devicesConfigs?.map((item) => {
    if (item.id === DEVICES.MLB.KIT_ESSENCIAL) {
      return '';
    }

    const price =
      (+item?.price || 0)?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 0;

    const suggestedSalePrice =
      (+item?.suggested_sale_price || 0)?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 0;

    devicesLabel += `<span class="list">${item?.model}: compre a ${price} e venda por ${suggestedSalePrice}.</span>`;

    return '';
  });

  const getPrizeValuePerId = (id) => {
    const value = (
      +prizesConfigs?.find((item) => item.group_id === id)?.prizes?.max_prize_amount_per_month || 0
    ).toLocaleString('pt-BR');

    const formattedValue = (+value)?.toFixed(0);

    return formattedValue;
  };

  const filteredPrizes = configs?.prizesConfigs.filter(
    (prizeConfig) => prizeConfig.group_id === GROUP_ID.MLB.APRENDIZ_WITHOUT_PURCHASES,
  );

  return {
    title: i18n.gettext('Preguntas frecuentes'),
    faqs: [
      {
        title: i18n.gettext('¿Qué requisitos debo cumplir para ser revendedor?'),
        description: i18n.gettext(
          'Solo necesitas tener una cuenta en Mercado Pago o Mercado Libre y registrarte con tus datos en el programa.',
        ),
      },
      {
        title: i18n.gettext('¿Cuáles son las ventajas de ser revendedor de Mercado Pago?'),
        description: i18n.jsx.gettext(
          'Siendo un revendedor de Mercado Pago, cuentas con:{0}{1}Descuentos especiales y envío gratis en la compra de lectores.{2}{1}Ganancias con la reventa de lectores.{2}{1}Incentivos en dinero basados en tu desempeño.{2}{1}Flexibilidad para trabajar la hora y el día que quieras.{2}{1}Capacitaciones para ayudarte a aumentar tus ganancias y mejorar tu estrategia de venta.{2}{1}Acceso al préstamo preaprobado por Mercado Crédito. {3}Consulta las condiciones{4} para aprovechar ese beneficio.{2}',
          {
            tags: {
              0: '<br /><br />',
              1: '<span class="list">',
              2: '</span>',
              3: '<a href="https://www.mercadopago.com.br/mercado-credito/creditos-empresa-online#fixed_term_loan" target="_blank">',
              4: '</a>',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Puedo ser un revendedor teniendo un trabajo remunerado fijo?'),
        description: i18n.gettext(
          'Sí. Muchos revendedores tienen un trabajo remunerado fijo y revenden lectores Point para aumentar sus ingresos. Tú determinas tus horarios y cuánto tiempo deseas dedicarte a la función de revendedor.',
        ),
      },
      {
        title: i18n.gettext('¿Tengo que hacer alguna compra inicial?'),
        description: i18n.jsx.gettext(
          'Sí. Puedes comenzar comprando un kit con {2} lectores que cuesta a partir de $ {3}. Si no cuentas con esta inversión, puedes solicitar el {0}préstamo preaprobado de Mercado Crédito.{1}',
          {
            tags: {
              0: '<a href="https://www.mercadopago.com.br/mercado-credito/creditos-empresa-online#fixed_term_loan" target="_blank">',
              1: '</a>',
            },
            replacements: {
              2: filteredPrizes[0]?.prizes.min_purchases_to_earn_prize,
              3: filteredPrizes[0]?.prizes.min_investment,
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Puedo venderle lectores a cualquier persona?'),
        description: i18n.jsx.gettext(
          'Sí, puedes vender lectores Point a cualquier persona. No obstante, para participar en los incentivos y asegurar un buen desempeño en el programa, es importante que tus ventas sean a nuevos clientes. Es decir, a personas que no han usado lectores Point en los últimos 12 meses.{0}Para apoyarte en alcanzar este objetivo, te ofrecemos recursos con estrategias de venta efectivas, capacitaciones online y una herramienta especial que te permitirá verificar si tus potenciales compradores son nuevos clientes.',
          {
            tags: {
              0: '<br /><br />',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Cuánto tiempo demora en finalizar mi registro?'),
        description: i18n.gettext(
          'Si todos tus datos están correctos, el registro se completa en el momento. Justo después, tendrás acceso al programa.',
        ),
      },
      {
        title: i18n.gettext('¿Cuánto puedo recibir por mes en cada nivel?'),
        description: i18n.jsx.gettext(
          'Además de las ganancias de la reventa de lectores, puedes obtener incentivos en dinero:{0}{1}{3}Aprendiz:{4} hasta $ {5} mil por mes.{2}{1}{3}Especialista:{4} hasta $ {6} mil por mes.{2}{1}{3}Emprendedor:{4} hasta $ {7} mil por mes.{2}{1}{3}Top Emprendedor:{4} hasta $ {8} mil por mes.{2}',
          {
            tags: {
              0: '<br /><br />',
              1: '<span class="list">',
              2: '</span>',
              3: '<span class="bold">',
              4: '</span>',
            },
            replacements: {
              5: getPrizeValuePerId(GROUP_ID.MLB.APRENDIZ_WITHOUT_PURCHASES),
              6: getPrizeValuePerId(GROUP_ID.MLB.ESPECIALISTA),
              7: getPrizeValuePerId(GROUP_ID.MLB.EMPREENDEDOR),
              8: getPrizeValuePerId(GROUP_ID.MLB.TOP_EMPREENDEDOR),
            },
          },
        ),
      },
      {
        title: i18n.gettext(
          '¿Cómo puedo capacitarme para alcanzar los niveles más altos del programa?',
        ),
        description: i18n.gettext(
          'Para ayudarte a crecer en el programa, ponemos a tu disposición capacitaciones online en el Portal de Reventa. Además, recibirás estrategias y recursos de venta directamente en tu e-mail y WhatsApp.',
        ),
      },
      {
        title: i18n.gettext('¿Cuál es el precio de compra y de venta de los lectores?'),
        description: i18n.jsx.gettext(
          'A continuación, te presentamos los precios de compra y las sugerencias de precio de venta para cada modelo:{0}{1}',
          {
            tags: {
              0: '<br /><br />',
              1: devicesLabel,
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Cómo se hace el pago de los incentivos?'),
        description: i18n.jsx.gettext(
          'Los pagos de los incentivos se realizan directamente en tu cuenta de Mercado Pago de la siguiente manera:{0}{0}{1}{3}Incentivo de ventas:{4} hasta el 10.º día hábil de cada mes.{2}{1}{3}Incentivo de activación:{4} hasta 5 días después de haberlo ganado.{2}{0}El pago de los incentivos considera el calendario de São Paulo.',
          {
            tags: {
              0: '<br />',
              1: '<span class="list">',
              2: '</span>',
              3: '<span class="bold">',
              4: '</span>',
            },
          },
        ),
      },
    ],
  };
};
