const React = require('react');
const PropTypes = require('prop-types');

const { lazy, useEffect, useState } = React;

const { useRebrandContext } = require('@context/rebrand-context');

const CustomIcons = require('./custom');

const AndesIcons = {};

const renderAndesIcon = (name, color) => {
  if (!AndesIcons[name]) {
    AndesIcons[name] = lazy(() =>
      import(`@andes/icons/${name}.js`).catch(() => {
        return { default: CustomIcons.ResellersProgram };
      }),
    );
  }

  const IconComponent = AndesIcons[name];

  return <IconComponent color={color} />;
};

const renderCustomIcon = (name, color) => {
  const IconComponent = CustomIcons[name];

  return IconComponent ? <IconComponent color={color} /> : null;
};

const Icon = ({ name, color }) => {
  const [component, setComponent] = useState(null);

  const { colors } = useRebrandContext();

  useEffect(() => {
    const iconColor = color || colors.ANDES_TEXT_BRAND;

    const [library, iconName] = name.split('_');

    let icon = renderCustomIcon(iconName || name, iconColor);

    if (library === 'andes') {
      icon = renderAndesIcon(iconName, iconColor);
    }

    setComponent(icon);
  }, [color, colors, name]);

  return component;
};

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
};

module.exports = Icon;
