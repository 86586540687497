// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useCallback, useEffect, useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { Text } = require('@andes/typography');

const { getExternalURLs } = require('@utils/navigation');

const BASE_CLASS_NAME = 'success-with-account';
const COUNTER_TIME = 5;

const SuccessWithAccount = () => {
  const [counter, setCounter] = useState(COUNTER_TIME);

  const { customerId, siteId, traficOrigin } = useFormContext();
  const { assetsPath } = useRebrandContext();
  const { i18n } = useI18n();

  const assetsBasePath = siteId === 'MLC' ? `${assetsPath}/` : '';

  const images = [
    {
      src: `${assetsBasePath}animated-loading-first-image.webp`,
      srcFallback: `${assetsBasePath}animated-loading-first-image.png`,
    },
    {
      src: `${assetsBasePath}animated-loading-second-image.webp'`,
      srcFallback: `${assetsBasePath}animated-loading-second-image.png`,
    },
  ];

  const externalUrls = getExternalURLs(siteId, traficOrigin);

  const redirectToMP = useCallback(() => {
    const custIdParam = `cust_id=${customerId}`;

    let baseUrl = externalUrls.ACCOUNT_CONFIRMATION;

    baseUrl += baseUrl.includes('?') ? `&${custIdParam}` : `?${custIdParam}`;

    window.open(baseUrl, '_self');
  }, [externalUrls, customerId]);

  useEffect(() => {
    const timer = setInterval(
      () =>
        setCounter((state) => {
          const newState = state - 1;

          return String(newState).padStart(2, '0');
        }),
      1000,
    );

    if (counter <= 0) {
      clearInterval(timer);

      return redirectToMP();
    }

    return () => clearInterval(timer);
  }, [counter, redirectToMP]);

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__animation`}>
        <ProgressIndicatorCircular
          id="progress-redirect"
          modifier="block"
          size="xlarge"
          srLabel="Acceder a la cuenta"
          type="indeterminate"
        />
        {images.map((image, idx) => (
          <div key="image" id={`step-${idx}`}>
            <Image height={48} src={image.src} srcFallback={image.srcFallback} width={48} />
          </div>
        ))}
      </div>
      <Text component="h2" weight="semibold">
        {i18n.gettext('Inicia sesión en tu cuenta de Mercado Pago')}
      </Text>
    </div>
  );
};

module.exports = SuccessWithAccount;
