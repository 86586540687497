// i18nLocalesOverride:['es-AR','es-MX','es-CL','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Card } = require('@andes/card');
const { CarouselSnapped, CarouselSnappedSlide } = require('@andes/carousel-snapped');
const { Title, Text } = require('@andes/typography');

const SliderCard = (props) => {
  const {
    arrows = false,
    imageSize,
    isMobile,
    items,
    label,
    mode = 'light',
    scroll = 'hidden',
    type = 'card',
  } = props;

  if (isMobile) {
    return (
      <div className="slider-card" data-arrows={arrows} data-type={type}>
        <CarouselSnapped
          pagination={{
            mode,
            position: 'bottom',
          }}
          scroll={scroll}
          slidesPerView={1}
          spacing={16}
          srLabel={label}
        >
          {items?.map((item) => (
            <CarouselSnappedSlide key={item.id}>
              <Card shadow="elevated">
                <Image
                  alt={item.alt}
                  src={item.icon}
                  srcFallback={item.iconFallback}
                  {...(imageSize?.width && { width: imageSize.width })}
                  {...(imageSize?.height && { height: imageSize.height })}
                />
                <Title component="h3" size="s">
                  {item.title}
                </Title>
                <Text component="p" size="s">
                  {item.description}
                </Text>
                {item.aditional && (
                  <Text component="p" size="s">
                    {item.aditional}
                  </Text>
                )}
              </Card>
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    );
  }

  return (
    <div className="slider-card" data-arrows={arrows} data-type={type}>
      {items?.map((item) => (
        <Card key={item.title} shadow="elevated">
          <Image
            alt={item.alt}
            src={item.icon}
            srcFallback={item.iconFallback}
            {...(imageSize?.width && { width: imageSize.width })}
            {...(imageSize?.height && { height: imageSize.height })}
          />
          <Title component="h3" size="s" type="title">
            {item.title}
          </Title>
          <Text component="p">{item.description}</Text>
          {item.aditional && (
            <Text component="p" size="s">
              {item.aditional}
            </Text>
          )}
        </Card>
      ))}
    </div>
  );
};

SliderCard.propTypes = {
  arrows: PropTypes.bool,
  imageSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      aditional: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      icon: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  scroll: PropTypes.string,
  type: PropTypes.oneOf(['card', 'transparent']),
  mode: PropTypes.oneOf(['dark', 'light']),
  label: PropTypes.string,
};

module.exports = SliderCard;
