// i18nLocalesOverride:['es-CL']

const React = require('react');

const { Image } = require('nordic/image');
const { Title } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');
const { useLandingContext } = require('@context/landing-context');

const BASE_CLASS_NAME = 'point-brands';

const PointBrands = () => {
  const { i18n } = useI18n();
  const { isMobile } = useLandingContext();

  const brands = [
    {
      alt: 'Medios de pago aceptados Mercado Credito',
      imgUrl: 'brand-mercado-credito.webp',
      srcFallback: 'brand-mercado-credito.png',
    },
    {
      alt: 'Medios de pago aceptados Mastercard',
      imgUrl: 'brand-mastercard.webp',
      srcFallback: 'brand-mastercard.png',
    },
    {
      alt: 'Medios de pago aceptados Visa',
      imgUrl: 'brand-visa.webp',
      srcFallback: 'brand-visa.png',
    },
    {
      alt: 'Medios de pago aceptados American Express',
      imgUrl: 'brand-american-express.webp',
      srcFallback: 'brand-american-express.png',
    },
    {
      alt: 'Medios de pago aceptados Samsung Pay',
      imgUrl: 'brand-samsung-pay.webp',
      srcFallback: 'brand-samsung-pay.png',
    },
    {
      alt: 'Medios de pago aceptados Google Pay',
      imgUrl: 'brand-google-pay.webp',
      srcFallback: 'brand-google-pay.png',
    },
    {
      alt: 'Medios de pago aceptados Apple Pay',
      imgUrl: 'brand-apple-pay.webp',
      srcFallback: 'brand-apple-pay.png',
    },
    {
      alt: 'Medios de pago aceptados Mach',
      imgUrl: 'brand-mach.webp',
      srcFallback: 'brand-mach.png',
    },
    {
      alt: 'Medios de pago aceptados Red Compra',
      imgUrl: 'brand-red-compra.webp',
      srcFallback: 'brand-red-compra.png',
    },
  ];

  return (
    <div className={BASE_CLASS_NAME}>
      <Title component="h3" size={isMobile ? 's' : 'xl'}>
        {i18n.jsx.gettext('Aceptan los principales{0}medios de pago', {
          tags: { 0: isMobile ? '<br />' : ' ' },
        })}
      </Title>
      <div>
        {brands.map((brand) => (
          <Image
            key={brand.alt}
            alt={brand.alt}
            src={brand.imgUrl}
            srcFallback={brand.srcFallback}
          />
        ))}
      </div>
    </div>
  );
};

module.exports = PointBrands;
