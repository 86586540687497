// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useI18n } = require('nordic/i18n');

const { Button } = require('@andes/button');
const { Title, Text } = require('@andes/typography');
const Icon = require('../icons');

const tracking = require('@utils/tracking');

const { useRebrandContext } = require('@context/rebrand-context');

const BASE_CLASS_NAME = 'footer';

const Footer = () => {
  const { i18n } = useI18n();

  const { colors, isInRebrand } = useRebrandContext();

  const handleScroll = (position) => window?.scrollTo(0, position);

  const handleScrollToTopClick = () => {
    tracking('home', 'footer-scroll-to-top');
    handleScroll(0);
  };

  const handleScrollToTopKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      tracking('home', 'footer-scroll-to-top');
      handleScroll(0);
    }
  };

  return (
    <div className={BASE_CLASS_NAME}>
      <div
        aria-label="Volver a la parte superior de la landing"
        className="go-to-top"
        role="button"
        tabIndex={0}
        onClick={handleScrollToTopClick}
        onKeyDown={handleScrollToTopKeyDown}
      >
        <Icon color={colors.ANDES_TEXT_BRAND} name="andes_ChevronUp16" />
        <Icon color={colors.CUSTOM_BRAND_COLOR} name="ScrollToTop" />
      </div>
      <div
        className={`${BASE_CLASS_NAME}__content`}
        style={{ backgroundColor: isInRebrand ? '#ffe600' : '#068ee4' }}
      >
        <Title component="h1">{i18n.gettext('Empieza a revender con Mercado Pago')}</Title>
        <Text component="h2" size="m">
          {i18n.gettext('Únete al programa que te ayuda a aumentar tus ganancias.')}
        </Text>
        <Button
          className={`${BASE_CLASS_NAME}__content__button${isInRebrand ? '' : '--white'}`}
          hierarchy="loud"
          onClick={handleScrollToTopClick}
        >
          {i18n.gettext('Empezar a revender')}
        </Button>
      </div>
      <div className={`${BASE_CLASS_NAME}__address`}>
        <Text color="secondary" component="p" weight="regular">
          Avenida Apoquindo 4800, torre 2, piso 21, Las Condes, Región Metropolitana, Chile.
        </Text>
      </div>
    </div>
  );
};

module.exports = Footer;
