const React = require('react');
const PropTypes = require('prop-types');

const ScrollToTop = ({ color, height, width }) => (
  <svg
    fill="none"
    height={height || '41'}
    viewBox="0 0 154 41"
    width={width || '154'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.9525 0C42.7661 0 53.0044 40.9851 0.0917969 40.9851H153.908C104.071 40.9851 113.139 0 77.9525 0Z"
      fill={color}
    />
  </svg>
);

ScrollToTop.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

module.exports = ScrollToTop;
