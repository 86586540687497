const Battery24 = require('./battery-24');
const Bluetooth24 = require('./bluetooth-24');
const ResellersProgram = require('./resellers-program');
const ScrollToTop = require('./scroll-to-top');
const Wifi24 = require('./wifi-24');

module.exports = {
  Battery24,
  Bluetooth24,
  ResellersProgram,
  ScrollToTop,
  Wifi24,
};
