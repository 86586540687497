// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Button } = require('@andes/button');
const { Pill } = require('@andes/badge');
const { Text } = require('@andes/typography');
const PillAccount = require('../../pill-account');

const tracking = require('@utils/tracking');

const { INVITE_AND_WIN } = require('@domain/constants/external-urls');

const BASE_CLASS_NAME = 'error-account-blocked';

const ErrorAccountBlocked = () => {
  const { user, isWebview, siteId } = useFormContext();
  const { email, logged } = user;
  const { i18n } = useI18n();

  const handleButtonClick = () => {
    tracking('home', 'form-register-error-account-blocked-redicrect-mgm');

    window.open(INVITE_AND_WIN[siteId], '_self');
  };

  const renderContentBySiteId = () => {
    const commonAction = {
      onClick: () => handleButtonClick(),
      text: i18n.gettext('Conocer más'),
    };

    switch (siteId) {
      case 'MLB':
        return {
          title: i18n.gettext('Cuenta bloqueada en el programa'),
          description:
            isWebview || !logged
              ? i18n.jsx.gettext(
                  'La cuenta asociada a {2} está bloqueada. Para seguir ofreciendo lectores Point,{0}conoce el programa Invita y gana.{1}',
                  {
                    tags: {
                      0: "<span class='semibold'>",
                      1: '</span>',
                    },
                    replacements: {
                      2: email,
                    },
                  },
                )
              : i18n.jsx.gettext(
                  'Para seguir ofreciendo lectores Point, {0}conoce el programa Invita y gana.{1}',
                  {
                    tags: {
                      0: "<span class='semibold'>",
                      1: '</span>',
                    },
                  },
                ),
          action: commonAction,
        };

      case 'MLC':
      default:
        return {
          title: i18n.gettext('Cuenta bloqueada en el programa'),
          description:
            isWebview || !logged
              ? i18n.gettext(
                  'La cuenta asociada a {0} está bloqueada. Para seguir ofreciendo Point, conoce el programa Invita y gana.',
                  email,
                )
              : i18n.gettext('Para seguir ofreciendo Point, conoce el programa Invita y gana.'),
          action: commonAction,
        };
    }
  };

  const { action, description, title } = renderContentBySiteId();

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__icon`}>
        <Pill color="red" contentType="icon" />
      </div>
      <div className={`${BASE_CLASS_NAME}__texts`}>
        <Text color="negative" component="h2" weight="semibold">
          {title}
        </Text>
        <Text color="secondary" component="p">
          {description}
        </Text>
      </div>
      {!isWebview && logged && <PillAccount />}
      <Button fullWidth onClick={action.onClick}>
        {action.text}
      </Button>
    </div>
  );
};

module.exports = ErrorAccountBlocked;
