const React = require('react');
const PropTypes = require('prop-types');

const { Text, Title } = require('@andes/typography');
const { CarouselFree, CarouselFreeSlide } = require('@andes/carousel-free');

const DeviceCard = require('../device-card');

const Points = ({ devices = [], isMobile, siteId }) => {
  const isMLM = siteId === 'MLM';

  if (!devices?.length) {
    return null;
  }

  return (
    <div className={`point-discounts-wrapper ${siteId?.toLowerCase()}`}>
      <div className="point-discounts-wrapper__bg">
        <div className="point-discounts-container">
          <div className="point-discounts">
            <Title component="h2" size="l" type="title">
              {isMLM ? 'Compra con beneficios' : 'Comprá con beneficios exclusivos'}
            </Title>
            <Text component="p" size="xl">
              {isMLM
                ? 'Inversión mínima de 5 Point Blue.'
                : 'Vendé online o de forma presencial y generá ingresos por margen de venta.'}
            </Text>
            <div className="point-discounts-content">
              {isMobile ? (
                <CarouselFree>
                  {devices?.map((point) => (
                    <CarouselFreeSlide key={point.model}>
                      <DeviceCard device={point} siteId={siteId} />
                    </CarouselFreeSlide>
                  ))}
                </CarouselFree>
              ) : (
                devices?.map((point) => (
                  <DeviceCard key={point.model} device={point} siteId={siteId} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Points.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = Points;
