const React = require('react');

const Calendar16 = require('@andes/icons/Calendar16');
const Calendar24 = require('@andes/icons/Calendar24');
const History16 = require('@andes/icons/History16');
const History24 = require('@andes/icons/History24');
const Payment16 = require('@andes/icons/Payment16');
const Payment24 = require('@andes/icons/Payment24');
const StarOutlined24 = require('@andes/icons/StarOutlined24');

const { COLORS } = require('@utils/constants/colors');

module.exports = (isMobile, siteId) => {
  if (siteId === 'MLM') {
    return {
      title: 'Conviértete en representante y empieza a crecer',
      subtitle: 'Obtén ganancias por la venta de terminales y gana premios por tu desempeño.',
      items: [
        {
          icon: <Payment16 color={COLORS.common.ANDES_WHITE} />,
          text: 'Compra a precio de mayoreo.',
        },
        {
          icon: <History16 color={COLORS.common.ANDES_WHITE} />,
          text: 'Se tu propio jefe.',
        },
        {
          icon: <Calendar16 color={COLORS.common.ANDES_WHITE} />,
          text: 'Maneja tus tiempos.',
        },
      ],
      image: {
        className: 'hero__image-reseller',
        height: 660,
        width: 700,
        src: `img_hero${isMobile ? '-mobile' : ''}.webp`,
        srcFallback: `img_hero${isMobile ? '-mobile' : ''}.png`,
      },
    };
  }

  return {
    title: 'Convertite en revendedor de Mercado Pago',
    items: [
      {
        icon: <Payment24 color={COLORS.common.ANDES_WHITE} />,
        text: 'Comprá a precio mayorista',
      },
      {
        icon: <History24 color={COLORS.common.ANDES_WHITE} />,
        text: 'Hacelo en 2 clicks',
      },
      {
        icon: <Calendar24 color={COLORS.common.ANDES_WHITE} />,
        text: 'Maneja tus tiempos',
      },
      {
        icon: <StarOutlined24 color={COLORS.common.ANDES_WHITE} />,
        text: 'No necesitás experiencia',
      },
      {
        className: 'hero__list-text',
        text: '¿Llegaste como referido?<br /> Comprá con 25% de descuento con tope de hasta $ 1.000. Te reintegramos el descuento a fin de mes.',
      },
    ],
    image: {
      className: 'hero__image-reseller',
      height: 660,
      width: 700,
      src: `img_hero${isMobile ? '-mobile' : ''}.webp`,
      srcFallback: `img_hero${isMobile ? '-mobile' : ''}.png`,
    },
  };
};
