const React = require('react');
const PropTypes = require('prop-types');

const {
  CarouselSnapped: AndesCarouselSnapped,
  CarouselSnappedSlide: AndesCarouselSnappedSlide,
} = require('@andes/carousel-snapped');

const BASE_CLASS_NAME = 'carousel-snapped-wrapper';

const CarouselSnapped = ({
  className,
  items,
  spacing,
  srLabel,
  slidesPerView,
  thresholdSlide,
  visibility = true,
}) => {
  const hasItems = items?.length > 0;

  if (!hasItems) {
    return null;
  }

  return (
    <div className={`${BASE_CLASS_NAME} ${className || ''}`}>
      <AndesCarouselSnapped
        arrows={
          visibility && {
            size: 'large',
            visibility: 'always',
          }
        }
        pagination={false}
        scroll="visible"
        slidesPerView={slidesPerView}
        spacing={spacing}
        srLabel={srLabel}
        thresholdSlide={thresholdSlide}
        type="content"
      >
        {items.map((item, idx) => (
          <AndesCarouselSnappedSlide key={`carousel-snapped-slide-${idx}`}>
            {item}
          </AndesCarouselSnappedSlide>
        ))}
      </AndesCarouselSnapped>
    </div>
  );
};

CarouselSnapped.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node),
  spacing: PropTypes.oneOf([12, 16, 24]),
  srLabel: PropTypes.string,
  slidesPerView: PropTypes.number,
  thresholdSlide: PropTypes.number,
  visibility: PropTypes.bool,
};

module.exports = CarouselSnapped;
