// i18nLocalesOverride:['es-AR','es-MX']

const React = require('react');

const { Image } = require('nordic/image');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

module.exports = (siteId) => {
  if (siteId === 'MLM') {
    return {
      title: 'Descubre más ventajas de ser representante',
      imageSrc: 'discover-bg.png',
      items: [
        {
          icon: <FeedbackPositive24 />,
          title: 'Invierte y gana',
          description: ' Con una inversión inicial de $ 745 obtén hasta $ 3,950 de ganancia.',
        },
        {
          icon: <FeedbackPositive24 />,
          title: 'Oportunidad para tus clientes',
          description:
            'Con las terminales Point tus clientes pueden cobrar con tarjeta y ser más competitivos.',
        },
        {
          icon: <FeedbackPositive24 />,
          title: 'Estás en el equipo',
          description: 'Te respalda una de las compañías que más crece en Latinoamérica.',
        },
      ],
    };
  }

  return {
    title: 'Descubrí más ventajas de ser Revendedor de Mercado Pago',
    imageSrc: 'discover-bg.png',
    items: [
      {
        icon: <Image src="money-icon.png" />,
        title: 'Empezá sin inversión',
        description: 'Accedés a créditos para empezar y seguir creciendo en el programa.',
      },
      {
        icon: <Image src="star-icon.png" />,
        title: 'No necesitás experiencia',
        description: 'Te capacitamos en todas las dimensiones que necesitás.',
      },
      {
        icon: <Image src="calendar-icon.png" />,
        title: 'Se dueño de tu tiempo',
        description: 'Vos manejás tus horarios y decidís cuándo y cómo vender.',
      },
      {
        icon: <Image src="coins-icon.png" />,
        title: 'Aprovechá la oportunidad',
        description:
          'A medida que vendés más, accedés a herramientas y niveles para potenciar tus premios con Mercado Pago.',
      },
      {
        icon: <Image src="team-icon.png" />,
        title: 'Estás en el equipo',
        description: 'Crecés junto a una de las empresas más grandes de Latinoamérica.',
      },
    ],
  };
};
