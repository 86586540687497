// i18nLocalesOverride:['es-AR','es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { CarouselSnapped, CarouselSnappedSlide } = require('@andes/carousel-snapped');
const { Text, Title } = require('@andes/typography');

const configs = require('./config');

const Timeline = ({ isMobile, siteId }) => {
  const { i18n } = useI18n();

  const config = configs(i18n, siteId);

  const isMLM = siteId === 'MLM';

  return (
    <div className={`steps-wrapper ${siteId?.toLowerCase()}`}>
      <Title component="h2" size="l">
        {config.title}
      </Title>
      {config.subtitle && (
        <div className="steps-wrapper__subtitle">
          <Text component="p">{config.subtitle}</Text>
          {config.secondSubtitle && <Text component="p">{config.secondSubtitle}</Text>}
        </div>
      )}
      <div className="steps">
        {isMobile ? (
          <CarouselSnapped arrows={false} srLabel={config.title}>
            {config.steps.map((item) => (
              <CarouselSnappedSlide key={item.id}>
                <div className="step-item">
                  {item.image && <Image src={item.image} />}
                  <div>
                    {isMLM && (
                      <Text component="strong" size="xl">
                        {item.title}
                      </Text>
                    )}
                    <Text component="p" size="s">
                      {item.description}
                    </Text>
                  </div>
                </div>
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        ) : (
          config.steps.map((item) => (
            <div key={item.id} className="step-item">
              {item.image && <Image src={item.image} />}
              <div>
                {isMLM && (
                  <Text component="strong" size="xl">
                    {item.title}
                  </Text>
                )}
                <Text component="p" size="l">
                  {item.description}
                </Text>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

Timeline.propTypes = {
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = Timeline;
