const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');

const HeroItem = require('../hero-item');

const configs = require('./config');

const Hero = ({ isMobile, siteId }) => {
  const config = configs(isMobile, siteId);

  return (
    <div className={`hero__wrapper ${siteId?.toLowerCase()}`}>
      <div className="hero__wrapper-content">
        <div className="hero__texts">
          <h1>{config.title}</h1>
          {config.subtitle && <h4>{config.subtitle}</h4>}
          <div className="hero__list">
            {config.items.map((item) => (
              <HeroItem key={item.text} {...item} />
            ))}
          </div>
        </div>

        <div className="hero__images">
          <Image
            preload
            className={config.image.className}
            height={config.image.height}
            lazyload="off"
            src={config.image.src}
            srcFallback={config.image.srcFallback}
            width={config.image.width}
          />
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = Hero;
