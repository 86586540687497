const React = require('react');
const PropTypes = require('prop-types');

const { Text } = require('@andes/typography');

const HeroItem = ({ text = '', icon, className = '' }) => (
  <div className={`item-wrapper ${className || ''}`}>
    {icon && icon}
    <Text dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

HeroItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

module.exports = HeroItem;
