// i18nLocalesOverride:['es-MX']

const React = require('react');

const { Image } = require('nordic/image');
const { Typography } = require('@andes/typography');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const QRItem = require('@components/item-details');

const PointInfo = () => {
  const items = [
    {
      icon: <FeedbackPositive24 />,
      title: 'Más soluciones, más ventas',
      description:
        'Ahora puedes ofrecer la tarjeta débito a tus clientes y así aumentar tus ventas.',
    },
    {
      icon: <FeedbackPositive24 />,
      title: 'Tarjeta de débito sin datos impresos',
      description: 'Tus clientes podrán retirar dinero o pagar en cualquier comercio.',
    },
  ];

  return (
    <div className="point__wrapper">
      <div className="point">
        <div className="point-content">
          <Typography component="h3" size="l" type="title">
            Tarjeta de débito gratis con tus compras
          </Typography>
          <div className="point-image">
            <Image src="point-info-mobile.webp" srcFallback="point-info-mobile.png" />
          </div>
          {items.map((item) => (
            <QRItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

module.exports = PointInfo;
