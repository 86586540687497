// i18nLocalesOverride:['es-AR','es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Title } = require('@andes/typography');

const DiscoverItem = require('../item-details');

const configs = require('./config');

const Discover = ({ siteId }) => {
  const { title, imageSrc, items } = configs(siteId);

  return (
    <div className={`discover__wrapper ${siteId?.toLowerCase()}`}>
      <div className="discover">
        <div className="discover-content">
          <Title component="h3" size="l">
            {title}
          </Title>
          <div className="discover-image">
            <Image alt="Un chico sentado en un sillón usando la computadora" src={imageSrc} />
          </div>
          {items.map((item) => (
            <DiscoverItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

Discover.propTypes = {
  siteId: PropTypes.string,
};

module.exports = Discover;
