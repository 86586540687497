// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useState } = React;

const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');

const { Title } = require('@andes/typography');
const CarouselSnapped = require('../carousel-snapped');
const PointBrands = require('../point-brands');
const PointCard = require('../point-card');

const BASE_CLASS_NAME = 'point-info';

const PointInfo = () => {
  const [detailsIsVisible, setDetailsIsVisible] = useState([]);

  const { i18n } = useI18n();

  const { isMobile, devicesByGroup } = useLandingContext();

  const windowWidth = typeof window !== 'undefined' && window?.innerWidth;
  const detailsActiveNumberToShowSlider = windowWidth > 1600 ? 1 : 0;

  const hasDetailsActive = !!detailsIsVisible.length;
  const hasSliderPagination =
    detailsIsVisible.length > detailsActiveNumberToShowSlider || devicesByGroup?.length > 2;

  const handleDetailsVisibility = (id) => {
    setDetailsIsVisible((state) => {
      if (state.includes(id)) {
        return state.filter((item) => item !== id);
      }

      return [...state, id];
    });
  };

  const renderPointCards = () => {
    return devicesByGroup.map((item) => (
      <PointCard key={item.model} item={item} onDetailsIsVisible={handleDetailsVisibility} />
    ));
  };

  const getCarouselClassName = () => {
    let className = `${BASE_CLASS_NAME}__carousel`;

    if (hasDetailsActive) {
      className += ` ${BASE_CLASS_NAME}__carousel--expanded`;
    }

    if (hasSliderPagination) {
      className += ` ${BASE_CLASS_NAME}__carousel--pagination`;
    }

    return className;
  };

  if (!Array.isArray(devicesByGroup) || !devicesByGroup?.length) {
    return null;
  }

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <Title component="h1" size="l">
          {i18n.gettext('Conoce los lectores que vas a vender')}
        </Title>
        {isMobile ? (
          <div className={`${BASE_CLASS_NAME}__cards`}>{renderPointCards()}</div>
        ) : (
          <div className={hasSliderPagination ? `${BASE_CLASS_NAME}--pagination` : ''}>
            <CarouselSnapped
              className={getCarouselClassName()}
              items={renderPointCards()}
              slidesPerView={hasSliderPagination ? 1 : 2}
              spacing={24}
              srLabel="Conoce las Point Mini y Smart"
              thresholdSlide={hasSliderPagination ? 20 : 0}
              visibility={hasSliderPagination}
            />
          </div>
        )}
        <PointBrands />
      </div>
    </div>
  );
};

module.exports = PointInfo;
