// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useLandingContext } = require('@context/landing-context');

const { Accordion } = require('@andes/accordion');
const { Text, Title, TextList, TextListItem } = require('@andes/typography');

const { Card } = require('@andes/card');
const tracking = require('@utils/tracking');

const { HELP_URL, YOUTUBE_VIDEO_URL } = require('@domain/constants/external-urls');

const BASE_CLASS_NAME = 'faqs';

const Faqs = () => {
  const { i18n } = useI18n();

  const { isMobile } = useLandingContext();

  const items = [
    {
      title: i18n.gettext('¿Cuáles son los requisitos para registrarme?'),
      description: (
        <>
          <Text color="secondary">
            {i18n.gettext('Para unirte al Programa de Revendedores, solo necesitas:')}
          </Text>
          <TextList color="secondary" markerType="number" srLabel="Requisitos para registrarme">
            <TextListItem>
              {i18n.gettext('Ser una persona física mayor de dieciocho (18) años de edad.')}
            </TextListItem>
            <TextListItem>{i18n.gettext('Residir en Chile.')}</TextListItem>
            <TextListItem>{i18n.gettext('Crear o tener una cuenta de Mercado Pago.')}</TextListItem>
          </TextList>

          <Text className="margin-top-12" color="secondary">
            {i18n.jsx.gettext(
              'Para más información, consulta nuestros {0}Términos y condiciones{1}.',
              { tags: { 0: `<a href="${HELP_URL.MLC}">`, 1: '</a>' } },
            )}
          </Text>
        </>
      ),
    },
    {
      title: i18n.gettext('¿Qué ventajas tendría al registrarme en el programa?'),
      description: (
        <>
          <Text color="secondary">
            {i18n.gettext('Cuentas con las siguientes ventajas al registrarte:')}
          </Text>
          <TextList color="secondary" markerType="dot" srLabel="Ventajas al registrarme">
            <TextListItem>
              {i18n.gettext('Descuentos exclusivos y envío gratis en la compra de lectores Point.')}
            </TextListItem>
            <TextListItem>{i18n.gettext('Ganancias con la reventa de los lectores.')}</TextListItem>
            <TextListItem>{i18n.gettext('Premios en dinero según tu desempeño.')}</TextListItem>
            <TextListItem>
              {i18n.gettext('Flexibilidad para trabajar la hora y el día que quieras.')}
            </TextListItem>
            <TextListItem>
              {i18n.gettext(
                'Capacitaciones para ayudarte a aumentar tus ganancias y mejorar tu estrategia de venta.',
              )}
            </TextListItem>
          </TextList>
        </>
      ),
    },
    {
      title: i18n.gettext('¿Puedo tener un empleo y participar en el programa?'),
      description: i18n.gettext(
        'Sí. Muchas personas que hacen parte del programa complementan su empleo fijo con la reventa de lectores Point, aprovechando la flexibilidad del programa para ganar ingresos adicionales.',
      ),
    },
    {
      title: i18n.gettext('¿Cómo puedo empezar a comprar lectores Point?'),
      description: i18n.gettext(
        'Puedes comenzar a comprar lectores Point en el Portal de Revendedores tan pronto como completes tu registro en el programa.',
      ),
    },
    {
      title: i18n.gettext('¿A quién puedo vender lectores de tarjetas?'),
      description: i18n.gettext(
        'Puedes vender lectores Point a personas, emprendedores o comercios interesados en realizar transacciones de manera eficiente.',
      ),
    },
    {
      title: i18n.gettext('¿Cómo puedo ganar los premios?'),
      description: (
        <>
          <Text color="secondary">{i18n.gettext('Para ganar los premios, necesitas:')}</Text>
          <TextList color="secondary" markerType="number" srLabel="Como ganar los premios">
            <TextListItem>{i18n.gettext('Revender lectores Point a clientes.')}</TextListItem>
            <TextListItem>
              {i18n.gettext('Motivar a tus clientes a usar el lector Point en sus ventas diarias.')}
            </TextListItem>
          </TextList>
          <Text className="margin-top-12" color="secondary">
            {i18n.gettext(
              'Si tus clientes alcanzan un mínimo de ventas en un plazo establecido, podrás ganar diferentes premios.',
            )}
          </Text>
        </>
      ),
    },
    {
      title: i18n.gettext('¿Cuándo recibo el pago de mis premios?'),
      description: (
        <>
          <Text color="secondary">
            {i18n.gettext(
              'El pago de los premios se hará directamente a tu cuenta de Mercado Pago del siguiente modo:',
            )}
          </Text>
          <TextList color="secondary" markerType="dot" srLabel="Recibiendo los premios">
            <TextListItem>
              {i18n.jsx.gettext(
                '{0}Premio de activación:{1} recibirás tus ganancias los 7 días posteriores al cumplimiento de las metas o los primeros 10 días del mes siguiente, dependiendo de la facturación del Point y de tu nivel.',
                { tags: { 0: '<strong>', 1: '</strong>' } },
              )}
            </TextListItem>
            <TextListItem>
              {i18n.jsx.gettext(
                '{0}Premio de nuevo cliente:{1} recibirás tus ganancias máximo 7 días después de cumplir las metas.',
                { tags: { 0: '<strong>', 1: '</strong>' } },
              )}
            </TextListItem>
          </TextList>

          <Text className="margin-top-12" color="secondary">
            {i18n.jsx.gettext(
              'Para más información, consulta nuestros {0}Términos y condiciones{1}.',
              { tags: { 0: `<a href="${HELP_URL.MLC}">`, 1: '</a>' } },
            )}
          </Text>
        </>
      ),
    },
    {
      title: i18n.gettext('¿Qué herramientas me ofrecen para vender más?'),
      description: (
        <>
          <Text color="secondary">
            {i18n.gettext('Mercado Pago te ofrece varias herramientas para aumentar tus ventas:')}
          </Text>
          <TextList color="secondary" markerType="dot" srLabel="Herramientas para vender más">
            <TextListItem>
              {i18n.jsx.gettext(
                '{0}Capacitación inicial al registrarte:{1} aprende todo lo necesario sobre cómo y dónde vender.',
                { tags: { 0: '<strong>', 1: '</strong>' } },
              )}
            </TextListItem>
            <TextListItem>
              {i18n.jsx.gettext(
                '{0}Capacitación postventa:{1} descubre cómo ganar premios y aprovechar al máximo los lectores Point.',
                { tags: { 0: '<strong>', 1: '</strong>' } },
              )}
            </TextListItem>
            <TextListItem>
              {i18n.jsx.gettext(
                '{0}Recursos digitales:{1} accede a nuestro {2}canal de Youtube{3} para mejorar tus habilidades.',
                {
                  tags: {
                    0: '<strong>',
                    1: '</strong>',
                    2: `<a href="${YOUTUBE_VIDEO_URL.MLC}">`,
                    3: '</a>',
                  },
                },
              )}
            </TextListItem>
          </TextList>
        </>
      ),
    },
  ];

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <Title component="h1" size="l">
          {i18n.gettext('Preguntas frecuentes')}
        </Title>
        <div className={`${BASE_CLASS_NAME}__questions`}>
          {items.map(({ title, description }) => (
            <Card key={title} shadow="outline">
              <Accordion
                showDivider={false}
                title={
                  <Text component="h3" size={isMobile ? 'm' : 'l'} weight="semibold">
                    {title}
                  </Text>
                }
                onChange={() => tracking('home', `faq-${title}`)}
              >
                <Text color="secondary">{description}</Text>
              </Accordion>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

module.exports = Faqs;
