const React = require('react');
const PropTypes = require('prop-types');

const { useEffect, useState } = React;

const { resendConfirmationEmail } = require('@services/leads');
const { saveLead } = require('@services/lead');

const { getExternalURLs } = require('@utils/navigation');
const tracking = require('@utils/tracking');

const API_RESPONSE_TYPE = require('../constants/api-response-type');
const LEAD_STATUS = require('@domain/constants/lead-status');
const { trackingEvent } = require('@utils/tracking/tracking-events');

const COUNTER_TIME = 30;

const useForm = (props) => {
  const {
    apiResponseType,
    errors,
    onSetApiResponseType,
    onSetErrors,
    onSetShowErrors,
    siteId,
    traficOrigin,
    user,
  } = props;

  const [counter, setCounter] = useState(COUNTER_TIME);
  const [customerId, setCustomerId] = useState('');
  const [leadId, setLeadId] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const externalUrls = getExternalURLs(siteId, traficOrigin);

  const resendEmail = async (id) => {
    try {
      await resendConfirmationEmail(false, leadId || id);

      setCounter(COUNTER_TIME);

      onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL);
    } catch (err) {
      onSetApiResponseType(API_RESPONSE_TYPE.ERROR);
    }
  };

  const handleSaveRegisterResponse = (response) => {
    const {
      alreadyHadMpAccount,
      customerId: responseApiCustomerId,
      isNew,
      isReseller,
      status,
    } = response;

    const isPerndingConfirmation = !isNew && status === LEAD_STATUS.USER_PENDING_CONFIRMATION;
    const isPerndingConfirmationByEmail =
      !isNew && status === LEAD_STATUS.USER_PENDING_CONFIRMATION_BY_EMAIL;

    setCustomerId(responseApiCustomerId);

    if (isPerndingConfirmationByEmail) {
      trackingEvent('new_register_with_user_pending_confirmation_email');

      resendEmail(response?.id);

      return;
    }

    if (isPerndingConfirmation) {
      trackingEvent('new_register_with_user_pending_confirmation');

      onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT);

      return;
    }

    if (isReseller) {
      trackingEvent('user_is_reseller_but_not_logged');

      onSetApiResponseType(API_RESPONSE_TYPE.ACCOUNT_ALREADY_REGISTERED);

      return;
    }

    if (user.logged) {
      trackingEvent('new_register_with_mp_account_already_created');

      let baseUrl = externalUrls.ACCOUNT_CONFIRMATION;
      const custIdParam = `cust_id=${responseApiCustomerId}`;

      baseUrl += baseUrl.includes('?') ? `&${custIdParam}` : `?${custIdParam}`;

      window.open(baseUrl, '_self');

      return;
    }

    if (alreadyHadMpAccount) {
      trackingEvent('new_register_with_mp_account_already_created');

      onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITH_ACCOUNT);

      return;
    }

    trackingEvent('new_register_with_new_account');

    onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT);
  };

  const saveRegister = async (token) => {
    try {
      const { name, email, phone: phoneNumber } = user;

      const phone = phoneNumber?.replace(/\D+/g, '');

      const userPayload = {
        email,
        name,
        phone,
        phoneDetails: {
          areaCode: phone?.slice(0, 2),
          number: phone?.slice(2),
        },
        recaptchaToken: token,
        traficOrigin,
      };

      const resp = await saveLead(userPayload);

      setLeadId(resp?.data?.id);

      handleSaveRegisterResponse(resp?.data);
    } catch (error) {
      trackingEvent('new_register_with_error');

      onSetApiResponseType(API_RESPONSE_TYPE.ERROR);
    }
  };

  const handleFieldWithErrors = () => {
    const { email, name, phone, tyc } = user;

    const countriesWithoutPhoneRequired = ['MLC'];
    const isPhoneRequired = !countriesWithoutPhoneRequired.includes(siteId);

    const listErrors = errors;

    if (!email) {
      listErrors.push('MAIL');
    }

    if (!name) {
      listErrors.push('NAME');
    }

    if (!phone && isPhoneRequired) {
      listErrors.push('PHONE');
    }

    if (!tyc) {
      listErrors.push('TYC');
    }

    onSetShowErrors(true);

    onSetErrors(listErrors);

    return listErrors.length === 0;
  };

  const getRecapchaToken = (elements) => {
    const recaptchaElementId = '#g-recaptcha-response';

    const token = elements?.querySelector(recaptchaElementId)?.value;

    return token || '';
  };

  const validateForm = async (e) => {
    e.preventDefault();

    const token = recaptchaToken || getRecapchaToken(e.target);

    const formIsValid = handleFieldWithErrors();

    if (!formIsValid || (!token && !user.logged)) {
      trackingEvent('try_register_with_invalid_form');

      onSetShowErrors(true);
      setRecaptchaError(!token);

      return;
    }

    await saveRegister(token);

    setRecaptchaError(false);
    setRecaptchaToken(token);

    onSetShowErrors(false);
  };

  useEffect(() => {
    const showCounter = [
      API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT,
      API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL,
    ].includes(apiResponseType);

    if (!showCounter) {
      return () => {};
    }

    const timer = setInterval(
      () =>
        setCounter((state) => {
          const newState = state - 1;

          return String(newState).padStart(2, '0');
        }),
      1_000,
    );

    return () => clearInterval(timer);
  }, [apiResponseType]);

  return {
    counter,
    customerId,
    onResendEmail: resendEmail,
    recaptchaError,
    validateForm,
  };
};

useForm.propTypes = {
  apiResponseType: PropTypes.string,
  onSetApiResponseType: PropTypes.func,
  onSetErrors: PropTypes.func,
  onSetShowErrors: PropTypes.func,
  siteId: PropTypes.string,
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    logged: PropTypes.bool,
    name: PropTypes.string,
    phone: PropTypes.string,
    tyc: PropTypes.bool,
  }),
};

module.exports = useForm;
