const React = require('react');
const PropTypes = require('prop-types');

const { useState, useEffect, useRef } = React;

const BASE_CLASS_NAME = 'segmented-control';

const INDEXES_TO_SKIP_TO_ADD_SEPARATOR = 2;

const Segmented = ({ defaultIndex = 0, onChange, options }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const [switchWidth, setSwitchWidth] = useState(0);

  const optionRefs = useRef([]);
  const switcherRef = useRef(null);

  const handleOptionClick = (value) => {
    const optionIndex = options.findIndex((option) => option.value === value);

    setSelectedIndex(optionIndex);
    onChange(value);
  };

  const handleSwitchTransition = () => {
    const previousItems = Array.from({ length: selectedIndex });

    const translateX = previousItems.reduce((acc, _, index) => {
      return acc + optionRefs.current[index].offsetWidth;
    }, 0);

    switcherRef.current.style.transform = `translateX(${translateX}px)`;
  };

  const handleSize = () => {
    const width = optionRefs.current[selectedIndex].offsetWidth;

    setSwitchWidth(width);
  };

  useEffect(() => {
    if (optionRefs.current[selectedIndex]) {
      handleSize();
      handleSwitchTransition();
    }
  }, [selectedIndex]); // eslint-disable-line

  if (!options?.length) {
    return;
  }

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__options`}>
        {options.map((option, index) => {
          const isActive = selectedIndex === index;

          const nextIndex = selectedIndex + INDEXES_TO_SKIP_TO_ADD_SEPARATOR;
          const previousIndex = selectedIndex - INDEXES_TO_SKIP_TO_ADD_SEPARATOR;

          const rightSeparator = previousIndex >= 0 && previousIndex === index;
          const leftSeparator = nextIndex < options.length && nextIndex === index;

          const hasSeparator = !isActive && (leftSeparator || rightSeparator);
          const separatorClass = leftSeparator ? 'left-separator' : 'right-separator';

          return (
            <button
              key={index}
              ref={(el) => {
                optionRefs.current[index] = el;
              }}
              className={`${isActive ? 'active' : ''} ${hasSeparator ? separatorClass : ''}`}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </button>
          );
        })}
        <div ref={switcherRef} className="switcher" style={{ width: `${switchWidth}px` }} />
      </div>
    </div>
  );
};

Segmented.propTypes = {
  defaultIndex: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};

module.exports = Segmented;
