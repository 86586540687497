// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Checkbox } = require('@andes/checkbox');
const { Pill } = require('@andes/badge');
const { Text } = require('@andes/typography');

const { TYC_BY_SITE } = require('@domain/constants/external-urls');

const BASE_CLASS_NAME = 'tyc';

const Tyc = ({ isChecked = false, onHandleChange = () => {} }) => {
  const { showErrors, errors, siteId } = useFormContext();
  const { i18n } = useI18n();

  const hasError = showErrors && errors?.includes('TYC');

  return (
    <div className={BASE_CLASS_NAME} data-error={hasError}>
      <div className={`${BASE_CLASS_NAME}__checkbox`}>
        <Checkbox
          checked={isChecked}
          srLabel="Aceito os termos e condições"
          onChange={() => onHandleChange()}
        />
        <Text color="secondary" component="h3">
          {i18n.jsx.gettext('Acepto los {0}Términos y condiciones.{1}', {
            tags: {
              0: `<a href="${TYC_BY_SITE[siteId]}" rel="noreferrer" target="_blank">`,
              1: '</a>',
            },
          })}
        </Text>
      </div>
      {!isChecked && hasError && (
        <div className={`${BASE_CLASS_NAME}__error`}>
          <Pill color="red" contentType="icon" size="small" />
          <Text color="negative" component="p" size="xs">
            {siteId === 'MLB'
              ? i18n.gettext('Es necesario aceptarlos para avanzar.')
              : i18n.gettext('Debes aceptar para continuar.')}
          </Text>
        </div>
      )}
    </div>
  );
};

Tyc.propTypes = {
  isChecked: PropTypes.bool,
  onHandleChange: PropTypes.func,
};

module.exports = Tyc;
