/*
  Deprecated -> Please use lead.js
*/

const getRestClient = require('./restclient');

const restclient = getRestClient('/api/revendedores');

const resendConfirmationEmail = (token, id) => {
  const data = token ? { token } : { id };

  return restclient.post(`/leads/resend-confirmation-email`, {
    data,
  });
};

module.exports = { resendConfirmationEmail };
