const BRAND = {
  YELLOW: 'yellowblue-light',
  BLUE: 'cyan-light',
};

const COLORS = {
  [BRAND.BLUE]: {
    ANDES_ACCENT_COLOR: '#009ee3',
    ANDES_TEXT_COLOR_PRIMARY: 'rgba(0, 0, 0, 0.9)',
    CUSTOM_BRAND_COLOR: '#068ee4',
    ANDES_TEXT_BRAND: '#ffffff',
  },
  [BRAND.YELLOW]: {
    ANDES_ACCENT_COLOR: '#3483fa',
    ANDES_TEXT_COLOR_PRIMARY: 'rgba(255, 255, 255, 0.9)',
    CUSTOM_BRAND_COLOR: '#ffe600',
    ANDES_TEXT_BRAND: 'rgba(0, 0, 0, 0.9)',
  },
  common: {
    ANDES_BLACK: '#000000',
    ANDES_GRAY_800_SOLID: '#333333',
    ANDES_WHITE: '#ffffff',
  },
};

module.exports = { BRAND, COLORS };
