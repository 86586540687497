// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');

const { Title } = require('@andes/typography');

const CardDivider = require('@components/card-divider');
const SliderCard = require('@components/slider-card');

const Steps = ({ isMobile }) => {
  const { i18n } = useI18n();

  const items = [
    {
      id: 1,
      icon: 'mobile-registro.webp',
      iconFallback: 'mobile-registro.png',
      title: i18n.gettext('Regístrate'),
      description: i18n.gettext('Ingresa tus datos para finalizar tu registro en el programa.'),
      alt: 'uma pessoa se cadastrando',
    },
    {
      id: 2,
      icon: 'mobile-compra.webp',
      iconFallback: 'mobile-compra.png',
      title: i18n.gettext('Compra'),
      description: i18n.gettext('Accede al portal y compra un kit de lectores Point.'),
      alt: 'uma pessoa comprando um kit',
    },
    {
      id: 3,
      icon: 'mobile-venda.webp',
      iconFallback: 'mobile-venda.png',
      title: i18n.gettext('Vende'),
      description: i18n.gettext(
        'Encuentra nuevos clientes y ofrece el Point más adecuado para ellos.',
      ),
      alt: 'uma pessoa vendendo uma maquininha',
    },
    {
      id: 4,
      icon: 'mobile-incentiva.webp',
      iconFallback: 'mobile-incentiva.png',
      title: i18n.gettext('Incentiva'),
      description: i18n.gettext('Cuantos más clientes vendan, más podrás recibir.'),
      alt: 'uma pessoa incentivando os vendedores',
    },
  ];

  const imageSize = isMobile ? { width: 288 } : { width: 240, height: 152 };

  return (
    <div className="steps-wrapper">
      <div className="steps-wrapper__container">
        <CardDivider />
        <Title component="h2" size="l" type="title">
          {i18n.gettext('Conviértete en revendedor en 4 pasos')}
        </Title>
        <SliderCard
          label="Seja um revendedor em 4 passos"
          type="transparent"
          {...{ isMobile, imageSize, items }}
        />
      </div>
    </div>
  );
};

Steps.propTypes = {
  isMobile: PropTypes.bool,
};

module.exports = Steps;
