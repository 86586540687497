const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Text, Title } = require('@andes/typography');

const CardDivider = require('../card-divider');
const ItemDetails = require('../item-details');

const configs = require('./config');

const PointsInfo = ({ siteId }) => {
  const config = configs(siteId);

  return (
    <div className={`point-infos__wrapper ${siteId?.toLowerCase()}`}>
      <div className="point-infos__wrapper-container">
        <div className="point-infos">
          <div className="point-infos-content">
            <CardDivider />
            <Title component="h3" size="l">
              {config.title}
            </Title>
            <div className="point-image">
              <Image src={config.src} srcFallback={config.srcFallback} />
            </div>
            {config.benefits.map((item) => (
              <ItemDetails key={item.description} {...item} />
            ))}
          </div>
        </div>
        <div className="point-infos__cards">
          {config.secondTitle && (
            <Title component="h5" size="xs">
              {config.secondTitle}
            </Title>
          )}
          <div className="card__wrapper">
            {config.cards.map((item) => (
              <div key={item.title} className="card__wrapper-content">
                <Text component="p">
                  {item.title}
                  {item?.text && <Text component="span">{item.text}</Text>}
                </Text>
                <div className="card__items">
                  {item.list.map((flag) => (
                    <Image
                      key={flag.alt}
                      alt={flag?.alt || 'credit card'}
                      className="card-image"
                      src={flag.src}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PointsInfo.propTypes = {
  siteId: PropTypes.string,
};

module.exports = PointsInfo;
