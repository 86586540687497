const MIN_NAME_LENGTH = {
  MLB: 4,
  MLC: 3,
};

const MIN_PHONE_LENGTH = {
  MLB: 15,
  MLC: 17,
};

const validateEmail = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}(\.[A-Z]{2,6})?$/i;

  return regex.test(value);
};

const validateName = (value, siteId) => {
  return value.length >= MIN_NAME_LENGTH[siteId];
};

const validateOnlyLetters = (value) => {
  const regex = /^[a-zA-Z\s]*$/;

  return regex.test(value);
};

const validateHasDotAndAt = (value) => {
  const regex = /^[^@]*@[^.]*\..*$/;

  return regex.test(value);
};

const validatePhone = (value, siteId) => {
  value.replace(/\D+/g, '');

  return value.length === MIN_PHONE_LENGTH[siteId];
};

module.exports = {
  validateEmail,
  validateHasDotAndAt,
  validateName,
  validateOnlyLetters,
  validatePhone,
};
