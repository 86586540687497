const DEVICES = {
  MLB: {
    KIT_ESSENCIAL: 71,
  },

  MLC: {
    POINT_MINI: 59,
    POINT_SMART: 101,
  },
};

module.exports = DEVICES;
