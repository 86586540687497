const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { Card } = require('@andes/card');
const { Text, Title } = require('@andes/typography');
const ChevronRight16 = require('@andes/icons/ChevronRight16');

const tracking = require('@utils/tracking');

const configs = require('./config');

const Pogo = ({ siteId }) => {
  const { title, subtitle, image, text, tyc, action } = configs(siteId);

  return (
    <div className="pogo-info">
      <Card className="pogo-card">
        <div className="pogo-card__content">
          <Title component="h3" size="l">
            {title}
          </Title>
          <Title component="h5" size="xs">
            {subtitle}
          </Title>
          <div className="main-image">
            <Image
              height={image.height}
              src={image.src}
              srcFallback={image.srcFallback}
              width={image.width}
            />
          </div>
          <Text component="p">{text}</Text>
          <a
            href={action.url}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => tracking('home', 'pogo-chat')}
          >
            {action.text} <ChevronRight16 fill="#009EE3" />
          </a>
        </div>
      </Card>
      <Text className="pogo-footer" component="p" size="s">
        {tyc.text}
        <a href={tyc.linkUrl} onClick={() => tracking('home', 'pogo-terms')}>
          {tyc.linkText}
        </a>
        .
      </Text>
    </div>
  );
};

Pogo.propTypes = {
  siteId: PropTypes.string,
};

module.exports = Pogo;
