const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');

const Background = ({ isMobile, position = 'top' }) => {
  const imageUrl = {
    bottom: isMobile ? 'bg-bottom-blue-wave-mobile.webp' : 'bg-bottom-blue-wave.webp',
    top: isMobile ? 'bg-top-blue-wave-mobile.webp' : 'bg-top-blue-wave.webp',
  };

  const imageFallbackUrl = {
    bottom: isMobile ? 'bg-bottom-blue-wave-mobile.png' : 'bg-bottom-blue-wave.png',
    top: isMobile ? 'bg-top-blue-wave-mobile.png' : 'bg-top-blue-wave.png',
  };

  return <Image alt="" src={imageUrl[position]} srcFallback={imageFallbackUrl[position]} />;
};

Background.propTypes = {
  isMobile: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom']),
};

module.exports = Background;
