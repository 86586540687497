const React = require('react');

const { Image } = require('nordic/image');
const { Typography } = require('@andes/typography');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const CardDivider = require('@components/card-divider');
const LevelItem = require('@components/item-details');

const Levels = () => {
  const items = [
    {
      icon: <FeedbackPositive24 color="#009EE3" />,
      title: 'Aficionado',
      description: 'Gana hasta $ 890 por cada terminal que vendas.',
    },
    {
      icon: <FeedbackPositive24 color="#009EE3" />,
      title: 'Profesional',
      description:
        'Gana hasta $ 1,240 por cada terminal que vendas. Accede a más promociones y beneficios especiales.',
    },
    {
      icon: <FeedbackPositive24 color="#009EE3" />,
      title: 'Experto',
      description: 'Gana hasta $ 1,440 por cada terminal que vendas.',
    },
  ];

  return (
    <div className="levels__wrapper">
      <div className="levels">
        <div className="levels__content">
          <CardDivider />
          <Typography component="h3" size="l" type="title">
            Crece con los niveles del programa y accede a nuevos beneficios
          </Typography>
          <div className="levels-image">
            <Image height={532} src="levels.webp" srcFallback="levels.png" width={510} />
          </div>
          {items.map((item) => (
            <LevelItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

module.exports = Levels;
