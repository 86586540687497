// i18nLocalesOverride:['pt-BR']

const React = require('react');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Text, Title } = require('@andes/typography');
const ChevronRight16 = require('@andes/icons/ChevronRight16');

const CardDivider = require('@components/card-divider');

const tracking = require('@utils/tracking');

const Help = () => {
  const { i18n } = useI18n();

  const whatsappMessage = encodeURIComponent(
    i18n.gettext('¡Hola! Me gustaría obtener más información sobre el Programa de Revendedores.'),
  );

  return (
    <div className="help-wrapper">
      <div className="help-wrapper__container">
        <CardDivider />
        <Image alt="WhatsApp" src="contacto.png" title="WhatsApp" width={120} />
        <Title component="h2" size="s" type="title">
          {i18n.gettext('Estamos aquí para ayudarte')}
        </Title>
        <Text component="h3">
          {i18n.gettext('Si tienes alguna duda, habla con nosotros por WhatsApp.')}
        </Text>
        <Text
          color="link"
          component="a"
          href={`https://api.whatsapp.com/send/?phone=5511966192726&text=${whatsappMessage}&app_absent=0`}
          target="_blank"
          onClick={() => tracking('home', 'help-whatsapp-message')}
        >
          {i18n.gettext('Enviar mensaje')}
          <ChevronRight16 color="#009EE3" />
        </Text>
      </div>
    </div>
  );
};

module.exports = Help;
