/* eslint-disable jsx-a11y/control-has-associated-label */

const React = require('react');
const PropTypes = require('prop-types');

const { Text, Title } = require('@andes/typography');
const Close12 = require('@andes/icons/Close12');
const User16 = require('@andes/icons/User16');

const tracking = require('@utils/tracking');

const LoggedUserRegistration = ({ callbackUrl, email, name }) => {
  return (
    <div className="resellers-data">
      <Title component="h3" type="title">
        ¡Hola, {name}!
      </Title>
      <div className="resellers-data__content">
        <div className="user-email">
          <div className="user-email__icon-user">
            <User16 />
          </div>
          <Text component="p" size="xs">
            {email}
          </Text>
          <div
            className="user-email__icon-close"
            role="button"
            tabIndex={-1}
            onClick={() => {
              tracking('home', 'form-logout');

              window.open(callbackUrl, '_self');
            }}
            onKeyDown={() => {}}
          >
            <Close12 />
          </div>
        </div>
        <div className="register__other-account">
          <a href={callbackUrl} onClick={() => tracking('home', 'register-with-other-account')}>
            Registrarme con otra cuenta
          </a>
        </div>
      </div>
    </div>
  );
};

LoggedUserRegistration.propTypes = {
  callbackUrl: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
};

module.exports = LoggedUserRegistration;
