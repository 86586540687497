const { URL_PER_SITE } = require('@domain/constants/external-urls');

const getExternalURLs = (siteId, trafficOrigin) => {
  const site = { ...URL_PER_SITE[siteId] };

  Object.keys(site).forEach((key) => {
    site[key] += trafficOrigin ? `?${trafficOrigin.replace('&', '')}` : '';
  });

  return site;
};

module.exports = { getExternalURLs };
