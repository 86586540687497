// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const { useState } = React;

const { Card } = require('@andes/card');
const { Typography } = require('@andes/typography');

const CardDivider = require('@components/card-divider');
const Footer = require('./footer');
const DevicesTable = require('./devices-table');

const Calculator = ({ devicesConfig = {}, qrPrizeValue }) => {
  const [loading, setLoading] = useState(true);
  const [devicesTotals, setDevicesTotals] = useState(undefined);

  return (
    <div className="calculator__wrapper" data-loading={loading}>
      <Card className="calculator__container">
        <CardDivider />
        <div className="calculator">
          <Typography component="h3" size="l" type="title">
            Calculá tus ganancias
          </Typography>
          <Typography color="secondary" size="xl">
            Seleccioná la cantidad de productos y Kits QR.
          </Typography>
          <div className="calculator__content">
            <Card className="calculator__card">
              <DevicesTable
                devicesConfig={devicesConfig}
                devicesTotals={devicesTotals}
                qrPrizeValue={qrPrizeValue}
                onSetDevicesTotals={setDevicesTotals}
                onSetLoading={setLoading}
              />
              <Footer devicesTotals={devicesTotals} />
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
};

Calculator.propTypes = {
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  qrPrizeValue: PropTypes.string,
};

module.exports = Calculator;
