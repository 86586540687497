const React = require('react');
const PropTypes = require('prop-types');

const { useRebrandContext } = require('@context/rebrand-context');

const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { Text } = require('@andes/typography');
const Icon = require('../icons');

const BASE_CLASS_NAME = 'quick-access-card';

const QuickAccessCard = ({ icon, href, text }) => {
  const { colors } = useRebrandContext();

  return (
    <Card className={BASE_CLASS_NAME} shadow="elevated">
      <button>
        <Button hierarchy="transparent" href={href} name={text} srLabel={text}>
          {icon && icon}
          <Text color="primary" component="h3" weight="semibold">
            {text}
          </Text>
          <Icon color={colors.ANDES_ACCENT_COLOR} name="andes_ChevronDown16" />
        </Button>
      </button>
    </Card>
  );
};

QuickAccessCard.propTypes = {
  icon: PropTypes.node,
  href: PropTypes.string,
  text: PropTypes.string,
};

module.exports = QuickAccessCard;
