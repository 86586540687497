const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');

const ButtonFixed = ({ isMobile, isLogged = false, siteId = 'MLA' }) => {
  const isMLM = siteId === 'MLM';

  let scrollPos;

  if (isMobile && typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 1300) {
        document.getElementById('fixed-button').classList.add('has-scroll');
      }

      if (window.scrollY < scrollPos) {
        document.getElementById('fixed-button').classList.remove('has-scroll');
      }

      scrollPos = window.scrollY;
    });
  }

  const loggedTitle = isMLM ? 'Quiero ser representante' : 'Quiero ser revendedor';

  return (
    <div className="button-fixed-wrapper" id="fixed-button">
      <Button hierarchy="loud" onClick={() => window.scrollTo(0, 750)}>
        {isLogged ? loggedTitle : 'Registrar datos'}
      </Button>
    </div>
  );
};

ButtonFixed.propTypes = {
  isLogged: PropTypes.bool,
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = ButtonFixed;
