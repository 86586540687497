const React = require('react');
const PropTypes = require('prop-types');

const { createContext, useContext, useMemo, useState } = React;

const useForm = require('../hooks/use-form');
const getResponseType = require('../utils/get-response-type');

const FormContext = createContext(null);

const FormProvider = ({ children, ...props }) => {
  const { isWebview, siteId, traficOrigin, user } = props;

  const [apiResponseType, setApiResponseType] = useState(getResponseType(user));

  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState(user?.fullName || '');
  const [phone, setPhone] = useState('');
  const [tyc, setTyc] = useState(false);

  const logged = user?.logged;
  const pictureUrl = user?.pictureUrl || '';

  const { customerId, validateForm, counter, onResendEmail, recaptchaError } = useForm({
    apiResponseType,
    errors,
    onSetApiResponseType: setApiResponseType,
    onSetErrors: setErrors,
    onSetShowErrors: setShowErrors,
    siteId,
    traficOrigin,
    user: { name, email, phone, tyc, logged },
  });

  const value = useMemo(
    () => ({
      apiResponseType,
      counter,
      customerId,
      errors,
      isWebview,
      onResendEmail,
      onSetApiResponseType: setApiResponseType,
      onSetEmail: setEmail,
      onSetErrors: setErrors,
      onSetName: setName,
      onSetPhone: setPhone,
      onSetShowErrors: setShowErrors,
      onSetTyc: setTyc,
      recaptchaError,
      showErrors,
      siteId,
      traficOrigin,
      user: { email, logged, name, phone, pictureUrl, tyc },
      validateForm,
    }),
    [
      apiResponseType,
      counter,
      customerId,
      email,
      errors,
      isWebview,
      logged,
      name,
      onResendEmail,
      phone,
      pictureUrl,
      recaptchaError,
      showErrors,
      siteId,
      traficOrigin,
      tyc,
      validateForm,
    ],
  );

  return (
    <FormContext.Provider
      value={{
        ...props,
        ...value,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useFormContext = () => useContext(FormContext);

module.exports = { useFormContext, FormProvider };

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isWebview: PropTypes.bool,
  siteId: PropTypes.string,
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    blocked: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    isReseller: PropTypes.bool,
    logged: PropTypes.bool,
    phone: PropTypes.string,
    pictureUrl: PropTypes.string,
  }),
};
