const mla = require('./mla');
const mlb = require('./mlb');
const mlm = require('./mlm');

module.exports = (i18n, devices, siteId, configs) => {
  switch (siteId) {
    case 'MLB':
      return mlb(i18n, configs, devices);
    case 'MLM':
      return mlm(i18n, devices);
    case 'MLA':
    default:
      return mla(i18n);
  }
};
