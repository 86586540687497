const tracking = require('@utils/tracking');

const trackingEvent = (event) => {
  if (event.includes('new_register')) {
    const registerWithSuccess = !event.includes('with_error');

    tracking('lead', registerWithSuccess, true);
  }

  tracking('home', event);
};

module.exports = { trackingEvent };
