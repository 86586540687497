// i18nLocalesOverride:['es-AR']
/* eslint-disable jsx-a11y/control-has-associated-label */

const React = require('react');
const PropTypes = require('prop-types');

const { Text, Title } = require('@andes/typography');
const Close12 = require('@andes/icons/Close12');
const User16 = require('@andes/icons/User16');

const tracking = require('@utils/tracking');

const LoggedForm = ({ callbackUrl = '', email, hasLeader = false, name }) => {
  const redirectUrl = `https://www.mercadolibre.com/jms/mla/lgz/logout?go=${callbackUrl}`;

  return (
    <div className="resellers-data mb-33">
      <Title className="bold text-center" component="h3" type="title">
        ¡Hola, {name}!
      </Title>
      {hasLeader && (
        <Text className="resellers-data__message mt-12" component="p">
          No es necesario que aceptés invitaciones de otros líderes.
          <span className="font-semibold"> Continúa con tu líder actual.</span>
        </Text>
      )}
      <div className="resellers-data__content">
        <div className="user-email">
          <div className="user-email__icon-user">
            <User16 />
          </div>
          <Text component="p" size="xs">
            {email}
          </Text>
          <div
            className="user-email__icon-close"
            role="button"
            tabIndex={-1}
            onClick={() => {
              tracking('home', 'form-logout');

              window.open(redirectUrl, '_self');
            }}
            onKeyDown={() => {}}
          >
            <Close12 />
          </div>
        </div>
        <div className="register__other-account">
          <a href={redirectUrl} onClick={() => tracking('home', 'register-with-other-account')}>
            Registrarme con otra cuenta
          </a>
        </div>
      </div>
    </div>
  );
};

LoggedForm.propTypes = {
  callbackUrl: PropTypes.string,
  email: PropTypes.string,
  hasLeader: PropTypes.bool,
  name: PropTypes.string,
};

module.exports = LoggedForm;
