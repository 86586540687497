// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');

const { CarouselFree, CarouselFreeSlide } = require('@andes/carousel-free');
const { CarouselSnappedSlide, CarouselSnapped } = require('@andes/carousel-snapped');
const { Typography } = require('@andes/typography');

const CardDivider = require('@components/card-divider');
const ItemDetails = require('@components/item-details');

const Levels = ({ deviceType = 'mobile' }) => {
  const items = [
    {
      type: 'level',
      icon: <Image alt="Aprendiz" src="aprendiz.webp" srcFallback="aprendiz.png" />,
      title: 'Aprendiz',
      description:
        'Empezá tu camino como revendedor y ganá hasta $ 2.500 por margen de reventa de cada Point y hasta $ 1.800 más, en premios.',
    },
    {
      type: 'level',
      icon: <Image alt="Emprendedor" src="emprendedor.png" srcFallback="emprendedor.png" />,
      title: 'Emprendedor',
      description:
        'Podés ganar más de $ 50.000* en premios más el margen de la reventa de cada Point.',
    },
    {
      type: 'level',
      icon: <Image alt="Experto" src="experto.webp" srcFallback="experto.png" />,
      title: 'Experto',
      description:
        'Podés ganar más de $ 100.000* en premios más el margen de la reventa de cada Point.',
    },
    {
      type: 'level',
      icon: <Image alt="Líder" src="preconsultor.webp" srcFallback="preconsultor.png" />,
      title: 'Preconsultor',
      description:
        'Podés ganar más de $ 150.000* en premios más el rendimiento de tus revendedores, además del margen de la reventa de cada Point.',
    },
    {
      type: 'level',
      icon: <Image alt="Consultor Certificado" src="consultor.webp" srcFallback="consultor.png" />,
      title: 'Consultor Certificado',
      description:
        'Podés ganar más de $ 250.000* en premios, también como referente de Mercado Pago y por la atención post-venta de comercios, además por el margen de la reventa de cada Point.',
    },
  ];

  return (
    <div className="levels__wrapper">
      <div className="levels">
        <div className="levels__content">
          <CardDivider />
          <Typography component="h2" size="l" type="title">
            Crecé con los niveles del programa y accedé a nuevos beneficios
          </Typography>

          <Image
            alt="fondo con efecto"
            className="background-effect__min"
            src="effect-1.webp"
            srcFallback="effect-1.png"
          />
          <Image
            alt="fondo con efecto"
            className="background-effect__max"
            src="effect-2.webp"
            srcFallback="effect-2.png"
          />

          {deviceType === 'mobile' ? (
            <CarouselFree>
              {items.map((item) => (
                <CarouselFreeSlide key={item.title}>
                  <ItemDetails {...item} />
                </CarouselFreeSlide>
              ))}
            </CarouselFree>
          ) : (
            <CarouselSnapped
              arrows
              pagination={deviceType === 'tablet'}
              scroll="hidden"
              slidesPerView={deviceType === 'tablet' ? 4 : 5}
            >
              {items.map((item) => (
                <CarouselSnappedSlide key={item.title}>
                  <ItemDetails {...item} />
                </CarouselSnappedSlide>
              ))}
            </CarouselSnapped>
          )}
          <Typography className="observation" component="p" size="xs">
            *Esta proyección de ganancia es mensual.
          </Typography>
        </div>
      </div>
    </div>
  );
};

Levels.propTypes = {
  deviceType: PropTypes.string,
};

module.exports = Levels;
