/* eslint-disable import/order */

const React = require('react');

const { useFormContext } = require('../context/use-form-context');

const { Card } = require('@andes/card');

const AccountAlreadyRegistered = require('./account-already-registered');
const Error = require('./error');
const ErrorAccountBlocked = require('./error-account-blocked');
const SuccessWithAccount = require('./success-with-account');
const SuccessWithoutAccount = require('./success-without-account');

const API_RESPONSE_TYPE = require('../constants/api-response-type');

const Feedbacks = () => {
  const { apiResponseType } = useFormContext();

  const renderComponent = () => {
    switch (apiResponseType) {
      case API_RESPONSE_TYPE.ACCOUNT_ALREADY_REGISTERED:
        return <AccountAlreadyRegistered />;

      case API_RESPONSE_TYPE.ERROR:
        return <Error />;

      case API_RESPONSE_TYPE.ERROR_ACCOUNT_BLOCKED:
        return <ErrorAccountBlocked />;

      case API_RESPONSE_TYPE.SUCCESS_WITH_ACCOUNT:
        return <SuccessWithAccount />;

      case API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT:
      case API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL:
        return <SuccessWithoutAccount />;

      default:
        return null;
    }
  };

  return <Card className="feedback-card">{renderComponent()}</Card>;
};

module.exports = Feedbacks;
