// i18nLocalesOverride:['es-MX']

const React = require('react');

const { useLandingContext } = require('@context/landing-context');

const Benefits = require('@components/benefits');
const ButtonFixed = require('@components/button-fixed');
const Discover = require('@components/discover');
const Fab = require('@components/fab');
const FAQList = require('@components/faq');
const Footer = require('@components/footer');
const HeaderFixed = require('@components/header-fixed');
const Hero = require('@components/hero');
const Pogo = require('@components/pogo');
const Points = require('@components/points');
const PointsInfo = require('@components/points-info');
const Timeline = require('@components/timeline');
const Video = require('@components/video');

const Calculator = require('./components/calculator');
const Levels = require('./components/levels');
const PointInfo = require('./components/point-info');
const Register = require('./components/register');

const LandingMlm = () => {
  const { data, devicesConfig, isMobile, siteId, traficOrigin, userData } = useLandingContext();

  const orderedDevices = Array.isArray(data?.devices)
    ? data.devices.sort((a, b) => {
        const orderA = devicesConfig?.devicesOrder?.[a?.id] || 99;
        const orderB = devicesConfig?.devicesOrder?.[b?.id] || 99;

        return orderA - orderB || a?.id - b?.id;
      })
    : [];

  const hasGroups = Array.isArray(data?.groups) && data.groups.length > 0;

  return (
    <div>
      <HeaderFixed isReseller={userData?.isReseller} siteId={siteId} />
      <Hero isMobile={isMobile} siteId={siteId} />
      <Register isMobile={isMobile} traficOrigin={traficOrigin} user={userData} />
      <Timeline isMobile={isMobile} siteId={siteId} />
      <Discover siteId={siteId} />
      <Points devices={orderedDevices} isMobile={isMobile} siteId={siteId} />
      <PointInfo />
      <Benefits isMobile={isMobile} siteId={siteId} />
      {hasGroups && (
        <Calculator
          devicesConfig={devicesConfig}
          groups={data.groups?.filter((g) => g.level && g.level !== 0)}
        />
      )}
      <Levels />
      <Video siteId={siteId} />
      <PointsInfo siteId={siteId} />
      <Pogo siteId={siteId} />
      <FAQList devices={orderedDevices} siteId={siteId} />
      <Footer isMobile={isMobile} siteId={siteId} />
      {!userData?.isReseller && (
        <ButtonFixed isLogged={userData?.logged} isMobile={isMobile} siteId={siteId} />
      )}
      <Fab
        isMobile={isMobile}
        title="Resuelve tus dudas"
        url="https://api.whatsapp.com/send/?phone=525524946542&text&app_absent=0"
      />
    </div>
  );
};

module.exports = LandingMlm;
