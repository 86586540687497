// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../context/use-form-context');

const { Text } = require('@andes/typography');
const Close20 = require('@andes/icons/Close20');
const User24 = require('@andes/icons/User24');

const tracking = require('@utils/tracking');

const { LANDING_PAGE_URL, LOGOUT_URL } = require('@domain/constants/external-urls');

const BASE_CLASS_NAME = 'pill-account';

const PillAccount = () => {
  const { user, siteId, traficOrigin } = useFormContext();
  const { i18n } = useI18n();

  const logout = () => {
    const landingUrl = encodeURIComponent(
      `${LANDING_PAGE_URL[siteId]}?${traficOrigin?.replace('&', '')}`,
    );

    const logOutUrl = `${LOGOUT_URL[siteId]}?go=${landingUrl}`;

    tracking('home', 'form-logout');

    window.open(logOutUrl, '_self');
  };

  return (
    <section className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__image`}>
        {user?.pictureUrl ? <Image height={40} src={user.pictureUrl} width={40} /> : <User24 />}
      </div>
      <div className={`${BASE_CLASS_NAME}__account`}>
        <Text component="h1" weight="semibold">
          {user.name}
        </Text>
        <Text color="secondary" size="s">
          {user.email}
        </Text>
      </div>
      <button
        aria-label={i18n.gettext('Salir de la cuenta actual')}
        className={`${BASE_CLASS_NAME}__action`}
        type="button"
        onClick={logout}
      >
        <Close20 />
      </button>
    </section>
  );
};

module.exports = PillAccount;
