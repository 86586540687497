// i18nLocalesOverride:['es-AR','es-MX','es-CL','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const ReCAPTCHA = require('react-google-recaptcha').default;

const FeedbackNegative12 = require('@andes/icons/FeedbackNegative12');

const { COLORS } = require('@utils/constants/colors');

const Recaptcha = ({ languageId = 'es-419', errors = [] }) => {
  const recaptchaRef = React.createRef();

  const BASE_CLASS_NAME = 'recaptcha';

  return (
    <div className={BASE_CLASS_NAME}>
      <ReCAPTCHA
        ref={recaptchaRef}
        hl={languageId}
        sitekey="6LcJYIMmAAAAABWwtUwJZtcG06MTFWruN_eE2Adi"
        size="normal"
      />
      <div className={`${BASE_CLASS_NAME}__error-container`}>
        {errors.map((e) => (
          <div key={e} className={`${BASE_CLASS_NAME}__error`}>
            <span className={`${BASE_CLASS_NAME}__error-icon`}>
              <FeedbackNegative12 color={COLORS.common.ANDES_WHITE} />
            </span>
            <span>{e}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

Recaptcha.propTypes = {
  languageId: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  handleToken: PropTypes.func,
};

module.exports = Recaptcha;
