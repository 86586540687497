// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { useCallback, useState } = React;

const { Image } = require('nordic/image');
const { Button } = require('@andes/button');

const tracking = require('@utils/tracking');

const { MICROSITIO_URL } = require('@domain/constants/external-urls');

const SCROLL_LIMIT_TO_SHOW_TABLET = { MLA: 1050, MLM: 900, MLC: 1000 };
const SCROLL_LIMIT_TO_SHOW_DESKTOP = { MLA: 1150, MLM: 1050, MLC: 1225 };
const SCROLL_LIMIT_TO_SHOW = 768;
const SCROLL_FORM_POSITION = 750;
const DESKTOP_WIDTH = 1024;

const HeaderFixed = ({ isReseller, siteId }) => {
  const [scrollY, setScrollY] = useState(0);

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      setScrollY(window.scrollY);
    });
  }

  const handleDimensions = useCallback(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }

    return 0;
  }, []);

  const handleClick = () => {
    if (isReseller) {
      tracking('home', 'button-header-fixed-go-to-portal');

      window.location.href = MICROSITIO_URL[siteId];

      return;
    }

    tracking('home', 'button-header-fixed');

    window.scrollTo(0, SCROLL_FORM_POSITION);
  };

  const showHeader =
    scrollY >=
      (handleDimensions() < DESKTOP_WIDTH
        ? SCROLL_LIMIT_TO_SHOW_TABLET[siteId || 'MLA']
        : SCROLL_LIMIT_TO_SHOW_DESKTOP[siteId || 'MLA']) &&
    handleDimensions() >= SCROLL_LIMIT_TO_SHOW;

  const buttonTextsBySite = {
    MLA: 'Quiero ser revendedor',
    MLM: 'Quiero ser representante',
    MLC: 'Quiero ser representante',
  };

  return (
    showHeader && (
      <div className="header-fixed">
        <section>
          <Image
            height={38}
            src="https://http2.mlstatic.com/frontend-assets/mp-web-navigation/ui-navigation/5.19.1/mercadopago/logo__large@2x.png"
            width={140}
          />
          <Button hierarchy="loud" onClick={() => handleClick()}>
            {isReseller ? 'Ir al portal' : buttonTextsBySite[siteId]}
          </Button>
        </section>
      </div>
    )
  );
};

HeaderFixed.propTypes = {
  isReseller: PropTypes.bool,
  siteId: PropTypes.string.isRequired,
};

module.exports = HeaderFixed;
