// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useLandingContext } = require('@context/landing-context');

const { CarouselFree, CarouselFreeSlide } = require('@andes/carousel-free');

const { COLORS } = require('@utils/constants/colors');

const Icon = require('../icons');
const QuickAccessCard = require('../quick-access-card');

const BASE_CLASS_NAME = 'quick-access';

const QuickAccess = () => {
  const { i18n } = useI18n();

  const { isMobile } = useLandingContext();

  const access = [
    {
      icon: <Icon color={COLORS.common.ANDES_GRAY_800_SOLID} name="ResellersProgram" />,
      text: i18n.gettext('Programa de Revendedores'),
      href: '#about-program',
    },
    {
      icon: <Icon color={COLORS.common.ANDES_GRAY_800_SOLID} name="andes_MercadoPuntos32" />,
      text: i18n.gettext('Premios y ganancias'),
      href: '#levels',
    },
    {
      icon: <Icon color={COLORS.common.ANDES_GRAY_800_SOLID} name="andes_Answer24" />,
      text: i18n.gettext('Testimonios de revendedores'),
      href: '#video',
    },
    {
      icon: <Icon color={COLORS.common.ANDES_GRAY_800_SOLID} name="andes_Warranty24" />,
      text: i18n.gettext('Beneficios de Mercado Pago'),
      href: '#advantages',
    },
  ];

  if (isMobile) {
    return (
      <div className={BASE_CLASS_NAME}>
        <CarouselFree spacing={24} srLabel="acceso rápido">
          {access.map((item) => (
            <CarouselFreeSlide key={item.text}>
              <QuickAccessCard {...item} />
            </CarouselFreeSlide>
          ))}
        </CarouselFree>
      </div>
    );
  }

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        {access.map((item) => (
          <QuickAccessCard key={item.text} {...item} />
        ))}
      </div>
    </div>
  );
};

module.exports = QuickAccess;
