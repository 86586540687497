const React = require('react');
const PropTypes = require('prop-types');

const { useEffect } = React;

const { useI18n } = require('nordic/i18n');
const Page = require('nordic/page');

const { AndesProvider } = require('@andes/context');
const { LandingProvider } = require('@context/landing-context');
const { RebrandProvider } = require('@context/rebrand-context');

const Commons = require('@components/commons');
const LandingMla = require('@templates/landing-mla');
const LandingMlb = require('@templates/landing-mlb');
const LandingMlc = require('@templates/landing-mlc');
const LandingMlm = require('@templates/landing-mlm');

const countryLanguage = require('@utils/country-language');

const View = (props) => {
  const { csrfToken, experiments, rebrand, siteId } = props;

  const { i18n } = useI18n();

  const locale = countryLanguage(siteId) || i18n.locale;

  const generateDeeplinkUrl = () => {
    const { href, search } = window.location;

    const cleanedSearch = search
      .replace(/[?&]alreadyRedirected=true/g, '')
      .replace(/[?&]redirect=true/g, '')
      .replace(/[?&]deep=true/g, '');

    const queryParam = new URLSearchParams(cleanedSearch);

    queryParam.set('alreadyRedirected', 'true');

    const universalUrl = `mercadopago://webview?url=${encodeURIComponent(
      `${href.split('?')[0]}?${queryParam.toString()}`,
    )}`;

    return universalUrl;
  };

  useEffect(() => {
    if (experiments === 'do-redirect' || window.location.search.includes('redirect=true')) {
      const userAgentAndroid = navigator.userAgent.toLowerCase().includes('android');
      const userAgentIos = navigator.userAgent.toLowerCase().includes('iphone');

      if (
        (userAgentAndroid || userAgentIos) &&
        (experiments === 'do-redirect' || window.location.search.includes('redirect=true')) &&
        !window.location.search.includes('alreadyRedirected=true')
      ) {
        window.location.href = generateDeeplinkUrl();
      }
    }
  }, []);

  const renderLandingTemplate = () => {
    switch (siteId) {
      case 'MLM':
        return <LandingMlm />;
      case 'MLC':
        return <LandingMlc />;
      case 'MLB':
        return <LandingMlb />;
      case 'MLA':
      default:
        return <LandingMla />;
    }
  };

  return (
    <AndesProvider csrfToken={csrfToken} locale={locale}>
      <Page className={siteId?.toLowerCase()} name="landing" state={props}>
        <Commons {...props} name="home" />
        <RebrandProvider rebrand={rebrand}>
          <LandingProvider {...props}>{renderLandingTemplate()}</LandingProvider>
        </RebrandProvider>
      </Page>
    </AndesProvider>
  );
};

View.propTypes = {
  csrfToken: PropTypes.string,
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
      price: PropTypes.number,
      suggested_sale_price: PropTypes.number,
    }),
  ),
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  deviceType: PropTypes.string,
  experiments: PropTypes.string,
  groupConfigs: PropTypes.shape({
    frequent_purchases_earning_amount: PropTypes.number,
    frequent_purchases_device_quantity: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
  isWebview: PropTypes.bool,
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
        min_investment: PropTypes.number,
      }),
    }),
  ),
  prizesSummary: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.number,
      groupName: PropTypes.string,
      prizes: PropTypes.shape({
        activationEarnings: PropTypes.string,
        investment: PropTypes.string,
        mostValuableDeviceMinBundle: PropTypes.number,
        mostValuableDeviceModel: PropTypes.string,
        newClientEarnings: PropTypes.string,
        salesMarginEarnings: PropTypes.string,
        totalEarnings: PropTypes.string,
      }),
    }),
  ),
  rebrand: PropTypes.shape({
    isInRebrand: PropTypes.bool,
    colors: PropTypes.shape({
      ANDES_ACCENT_COLOR: PropTypes.string,
      ANDES_TEXT_COLOR_PRIMARY: PropTypes.string,
      CUSTOM_BRAND_COLOR: PropTypes.string,
      ANDES_TEXT_BRAND: PropTypes.string,
    }),
  }),
  siteId: PropTypes.string.isRequired,
  traficOrigin: PropTypes.string,
  userData: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    logged: PropTypes.bool,
    phone: PropTypes.string,
    pictureUrl: PropTypes.string,
  }),
};

module.exports = View;
