// i18nLocalesOverride:['es-CL']

const React = require('react');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { Title } = require('@andes/typography');

const AdvantagesCard = require('../advantages-card');
const CarouselSnapped = require('../carousel-snapped');
const Icon = require('../icons');

const BASE_CLASS_NAME = 'advantages';
const COMPONENT_ID = 'advantages';

const Advantages = () => {
  const { i18n } = useI18n();

  const { assetsPath, colors, isInRebrand } = useRebrandContext();

  const { isMobile } = useLandingContext();

  const advantages = [
    {
      id: 1,
      color: isInRebrand ? 'dark' : 'dark-blue',
      icon: <Icon color={colors.ANDES_WHITE} name="andes_ToAgree20" />,
      legend: i18n.gettext('Cuenta gratis'),
      title: i18n.gettext('Crea una cuenta digital en minutos y sin costo'),
    },
    {
      id: 2,
      asset: (
        <Image
          alt=""
          src={`${assetsPath}/${
            isMobile ? 'advantages-smart-mobile.webp' : 'advantages-smart-desktop.webp'
          }`}
          srcFallback={`${assetsPath}/${
            isMobile ? 'advantages-smart-mobile.png' : 'advantages-smart-desktop.png'
          }`}
        />
      ),
    },
    {
      id: 3,
      color: isInRebrand ? 'light-yellow' : 'light-blue',
      icon: <Icon color={colors.ANDES_BLACK} name="andes_CreditCard20" />,
      legend: i18n.gettext('Tarjeta mastercard'),
      title: i18n.gettext('Obtén una tarjeta internacional gratuita'),
    },
    {
      id: 4,
      asset: (
        <Image
          alt=""
          src={`${assetsPath}/${
            isMobile ? 'advantages-mp-card-mobile.webp' : 'advantages-mp-card-desktop.webp'
          }`}
          srcFallback={`${assetsPath}/${
            isMobile ? 'advantages-mp-card-mobile.png' : 'advantages-mp-card-desktop.png'
          }`}
        />
      ),
    },
    {
      id: 5,
      color: isInRebrand ? 'dark' : 'dark-blue',
      icon: <Icon color={colors.ANDES_WHITE} name="andes_Wallet24" />,
      legend: i18n.gettext('Gestiona fácil'),
      title: i18n.gettext('Transfiere y gestiona tu dinero desde tu celular'),
    },
    {
      id: 6,
      asset: (
        <Image
          alt=""
          src={`${assetsPath}/${
            isMobile ? 'advantages-manage-mobile.webp' : 'advantages-manage-desktop.webp'
          }`}
          srcFallback={`${assetsPath}/${
            isMobile ? 'advantages-manage-mobile.png' : 'advantages-manage-desktop.png'
          }`}
        />
      ),
    },
  ];

  return (
    <div className={BASE_CLASS_NAME} id={COMPONENT_ID}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <Title component="h1" size="l">
          {i18n.gettext('Ventajas de Mercado Pago al registrarte')}
        </Title>
        <CarouselSnapped
          className={`${BASE_CLASS_NAME}__carousel`}
          items={advantages.map((advantage) => (
            <AdvantagesCard key={advantage.id} {...advantage} />
          ))}
          slidesPerView={isMobile ? 1 : 2}
          spacing={16}
          srLabel="vantagens do programa"
          thresholdSlide={isMobile ? 1 : 50}
        />
      </div>
    </div>
  );
};

module.exports = Advantages;
