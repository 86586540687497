// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { CardSecondary } = require('@andes/card');
const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');

const Icon = require('../icons');

const DEVICES = require('@domain/constants/devices');

const BASE_CLASS_NAME = 'point-card';

const PointCard = ({ item, onDetailsIsVisible }) => {
  const [expandedCard, setExpandedCard] = useState(false);

  const { i18n } = useI18n();
  const { colors } = useRebrandContext();
  const { isMobile } = useLandingContext();

  const hasDetails = item.details.length > 0;

  const iconsMap = {
    add: isMobile ? 'andes_Add16' : 'andes_Add24',
    substract: isMobile ? 'andes_Substract16' : 'andes_Substract24',
  };

  const deviceImageMap = {
    [DEVICES.MLC.POINT_MINI]: isMobile
      ? 'mercado-pago-point-mini-mobile.webp'
      : 'mercado-pago-point-mini.webp',
    [DEVICES.MLC.POINT_SMART]: isMobile
      ? 'mercado-pago-point-smart-mobile.webp'
      : 'mercado-pago-point-smart.webp',
  };

  const toggleCard = () => {
    onDetailsIsVisible(item.id);
    setExpandedCard((state) => !state);
  };

  return (
    <CardSecondary key={item.model} className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__content`}>
        <Image alt={item.model} src={deviceImageMap[item.id] || item.pictureUrl} />
        <div className={`${BASE_CLASS_NAME}__details`}>
          <div className={`${BASE_CLASS_NAME}__header`}>
            <Title
              className={`${BASE_CLASS_NAME}__model`}
              component="h3"
              size={isMobile ? 'm' : 'xl'}
            >
              {item.model}
            </Title>
            {!!item?.discountPercent && (
              <Pill color="green" contentType="text" size="small">
                {item.discountPercent}% OFF
              </Pill>
            )}
          </div>
          <Text className={`${BASE_CLASS_NAME}__price`} color="secondary" weight="semibold">
            {i18n.gettext('Cómpralo por {0}', item.price)}
          </Text>
          <Text className={`${BASE_CLASS_NAME}__sell-price`} color="secondary" size="s">
            {i18n.gettext('Véndelo por {0}', item.sugestedRetailPrice)}
          </Text>
          <Text
            className={`${BASE_CLASS_NAME}__profit`}
            color="positive"
            size={isMobile ? 'xs' : 'm'}
            weight="semibold"
          >
            {i18n.gettext('Gana {0}', item.gainByResale)}
          </Text>
        </div>
      </div>
      {expandedCard && (
        <div className={`${BASE_CLASS_NAME}__info`}>
          {item.details.map((info) => (
            <div key={info.description} className={`${BASE_CLASS_NAME}__info-item`}>
              <Icon color={colors.ANDES_TEXT_COLOR_PRIMARY} name={info.icon} />
              <Text color="secondary" size="m">
                {info.description}
              </Text>
            </div>
          ))}
        </div>
      )}
      {hasDetails && (
        <button className={`${BASE_CLASS_NAME}__button`} onClick={toggleCard}>
          <Icon
            color={colors.ANDES_TEXT_COLOR_PRIMARY}
            name={expandedCard ? iconsMap.substract : iconsMap.add}
          />
        </button>
      )}
    </CardSecondary>
  );
};

PointCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    discountPercent: PropTypes.number,
    pictureUrl: PropTypes.string,
    model: PropTypes.string,
    price: PropTypes.string,
    sugestedRetailPrice: PropTypes.string,
    gainByResale: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.node,
        description: PropTypes.string,
      }),
    ),
  }),
  onDetailsIsVisible: PropTypes.func,
};

module.exports = PointCard;
