const getRestClient = require('./restclient');

const restclient = getRestClient('/api/revendedores/v2/lead');

const clientHandleError = require('../utils/response-handlers/client-error');

class LeadService {
  async saveLead(data) {
    try {
      const response = await restclient.post('/save', {
        data,
      });

      return response;
    } catch (error) {
      clientHandleError(error);
    }
  }
}

module.exports = new LeadService();
