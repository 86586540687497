// i18nLocalesOverride:['es-CL']

const React = require('react');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');

const Icon = require('../icons');

const BASE_CLASS_NAME = 'about-program';
const COMPONENT_ID = 'about-program';

const AboutProgram = () => {
  const { i18n } = useI18n();

  const { assetsPath, colors } = useRebrandContext();

  const { isMobile } = useLandingContext();

  const imageName = isMobile
    ? 'about-program-reseller-point-mobile'
    : 'about-program-reseller-point';

  const items = [
    {
      icon: (
        <Pill color="accent" contentType="text" hierarchy="quiet">
          <Icon color={colors.ANDES_ACCENT_COLOR} name="andes_CartEmpty24" />
        </Pill>
      ),
      title: i18n.gettext('Compra y revende'),
      description: i18n.gettext('Adquiere lectores al mejor precio y gana con la reventa.'),
    },
    {
      icon: (
        <Pill color="accent" contentType="text" hierarchy="quiet">
          <Icon color={colors.ANDES_ACCENT_COLOR} name="andes_Payment24" />
        </Pill>
      ),
      title: i18n.gettext('Gana premios en dinero'),
      description: i18n.gettext('Cuánto más cobren tus clientes, más oportunidades de ganar.'),
    },
    {
      icon: (
        <Pill color="accent" contentType="text" hierarchy="quiet">
          <Icon color={colors.ANDES_ACCENT_COLOR} name="andes_MercadoLider24" />
        </Pill>
      ),
      title: i18n.gettext('Crece en el programa'),
      description: i18n.gettext('Mejora tu desempeño y sube de nivel para ganar más.'),
    },
  ];

  return (
    <div className={BASE_CLASS_NAME} id={COMPONENT_ID}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <Title component="h1" size="m">
          {i18n.gettext('Gestiona tu tiempo y lidera tu negocio')}
        </Title>
        <div className={`${BASE_CLASS_NAME}__items`}>
          {items.map((item) => (
            <div key={item.title} className={`${BASE_CLASS_NAME}__item`}>
              <div>{item.icon}</div>
              <div>
                <Title component="h3">{item.title}</Title>
                <Text color="secondary" size="s" weight="regular">
                  {item.description}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <Image
          alt="Una revendedora mostrando una Point"
          src={`${assetsPath}/${imageName}.webp`}
          srcFallback={`${assetsPath}/${imageName}.png`}
        />
      </div>
    </div>
  );
};

module.exports = AboutProgram;
