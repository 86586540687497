const React = require('react');

const { Image } = require('nordic/image');

const { formatPriceString } = require('@utils/formatter');
const { getMax } = require('@utils/array');

module.exports = (siteId, devicesConfig, groupConfigs) => {
  if (siteId === 'MLM') {
    return {
      title: 'Gana premios con tus terminales',
      prizes: [
        {
          type: 'benefit',
          icon: (
            <Image src="https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/premiouso--57006450.png" />
          ),
          description: `Hasta $ 100 con el Premio de uso Point`,
        },
        {
          type: 'benefit',
          icon: (
            <Image src="https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/nuevocliente--60777373.png" />
          ),
          description: 'Hasta $ 300 con el Premio de nuevo cliente',
        },
        {
          type: 'benefit',
          icon: (
            <Image src="https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/activacion--abc37a64.png" />
          ),
          description: 'Hasta $ 4,500 con los Premios de activación',
        },
      ],
    };
  }

  const tpv = formatPriceString(groupConfigs?.new_client_tpv_threshold || 0, siteId);

  const newClientPoint = formatPriceString(
    getMax(
      devicesConfig?.configs?.filter((item) => {
        return item.device_first_payment_cash_back || 0;
      }),
      'device_first_payment_cash_back',
    ) || 0,
    siteId,
  );

  const newClientQR = formatPriceString(groupConfigs?.qr_earned_by_seller || 0, siteId);

  return {
    title: 'Conocé más sobre cómo ganar dinero extra',
    prizes: [
      {
        type: 'benefit',
        icon: <Image alt="Icone Point" src="nuevos-clientes-icon.png" />,
        title: 'Premio por nuevo cliente Point',
        description: `Gana ${newClientPoint} por cada nuevo cliente que cobre  ${tpv} con Point.`,
      },
      {
        type: 'benefit',
        icon: <Image alt="Icone QR" src="qr-icon.png" />,
        title: 'Premio nuevo cliente QR',
        description: `Gana ${newClientQR} por cada nuevo cliente que cobre ${tpv} con QR.`,
      },
      {
        type: 'benefit',
        icon: <Image alt="Icone compras frecuentes" src="descuento-frecuentes-icon.png" />,
        title: 'Descuentos por compra frecuentes',
        description: 'Comprá todos los meses y accedé a un 20% de descuento.',
      },
      {
        type: 'benefit',
        icon: <Image alt="Icone sube de nivel" src="new-level-icon.png" />,
        title: 'Sube de nivel y gana mucho más',
        description: 'Incrementa tus ganancias y beneficios cuando subas de nivel.',
      },
    ],
  };
};
