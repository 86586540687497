// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useState } = React;

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Button } = require('@andes/button');
const { Text } = require('@andes/typography');

const tracking = require('@utils/tracking');

const API_RESPONSE_TYPE = require('../../constants/api-response-type');

const BASE_CLASS_NAME = 'success-without-account';

const SuccessWithoutAccount = () => {
  const { apiResponseType, counter, onResendEmail, siteId, user } = useFormContext();
  const { email } = user;
  const { i18n } = useI18n();

  const [isEmailResent, setIsEmailResent] = useState(
    apiResponseType === API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL,
  );

  const resendEmail = () => {
    tracking('home', 'form-register-success-create-account');

    onResendEmail();

    setIsEmailResent(true);
  };

  const registerWithAnotherAccount = () => {
    tracking('home', 'form-register-success-register-with-other-account');

    window.location.reload();
  };

  const renderContentBySiteId = () => {
    const commonActions = {
      primary: {
        disabled: counter > 0,
        hierarchy: 'loud',
        onClick: () => resendEmail(),
        text:
          counter > 0
            ? i18n.gettext('Reenviar e-mail en {0}', `00:${counter}`)
            : i18n.gettext('Reenviar e-mail'),
      },
      secondary: {
        disabled: false,
        hierarchy: 'transparent',
        onClick: () => registerWithAnotherAccount(),
        text: i18n.gettext('Registrar otro e-mail'),
      },
    };

    switch (siteId) {
      case 'MLB':
        return {
          title: i18n.gettext('Valida tu e-mail'),
          description: isEmailResent
            ? i18n.gettext(
                'Reenviamos la validación a {0}. Verifica tu bandeja de entrada y los correos no deseados.',
                email,
              )
            : i18n.gettext(
                'Accede al e-mail que enviamos a {0} y continúa con tu registro.',
                email,
              ),
          action: commonActions,
        };

      case 'MLC':
      default:
        return {
          title: i18n.gettext('Valida tu e-mail'),
          description: isEmailResent
            ? i18n.gettext(
                'Reenviamos la validación a {0}. Revisa tu bandeja de entrada y los correos no deseados.',
                email,
              )
            : i18n.gettext(
                'Revisa el e-mail que enviamos a {0} y continúa con tu registro.',
                email,
              ),
          action: commonActions,
        };
    }
  };

  const { action, description, title } = renderContentBySiteId();

  return (
    <div className={BASE_CLASS_NAME}>
      <Text component="h2" weight="semibold">
        {title}
      </Text>
      <Text color="secondary" component="p">
        {description}
      </Text>
      <div className={`${BASE_CLASS_NAME}__actions`}>
        <Button
          fullWidth
          disabled={action.primary.disabled}
          hierarchy={action.primary.hierarchy}
          onClick={action.primary.onClick}
        >
          {action.primary.text}
        </Button>
        <Button
          fullWidth
          disabled={action.secondary.disabled}
          hierarchy={action.secondary.hierarchy}
          onClick={action.secondary.onClick}
        >
          {action.secondary.text}
        </Button>
      </div>
    </div>
  );
};

module.exports = SuccessWithoutAccount;
