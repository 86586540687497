// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const { useCallback, useEffect, useState } = React;

const { TextField } = require('@andes/textfield');
const { Typography } = require('@andes/typography');

const { formatPriceString } = require('@utils/formatter');
const tracking = require('@utils/tracking');

const { CALC_COLS } = require('./configs');

const DevicesTable = (props) => {
  const {
    devicesConfig = {},
    devicesTotals = [],
    onSetDevicesTotals = () => {},
    onSetLoading = () => {},
    qrPrizeValue,
  } = props;

  const [devices, setDevices] = useState(undefined);

  const handleOriginalDevicesQuantity = useCallback(
    (response) => {
      const originalDevicesQty = response?.devices?.map((d) => ({
        id: d.id,
        price: 0,
        quantity: 0,
        client: 0,
        profit: 0,
        qr: 0,
      }));

      onSetDevicesTotals(originalDevicesQty);
    },
    [onSetDevicesTotals],
  );

  const handleSortedDevices = useCallback(
    (response) => {
      const devs = response?.devices
        ?.sort((a, b) => a.price_with_discount - b.price_with_discount)
        ?.map((d) => ({
          model: d.model,
          id: d.id,
          prizes: {
            client:
              response?.configs?.find((c) => c.device_id === d.id)
                ?.device_first_payment_cash_back || 0,
            profit: d.fake_price - d.price_with_discount,
            price: d.price_with_discount,
            qr: qrPrizeValue,
          },
        }));

      setDevices(devs);
      onSetLoading(!devs?.length);
    },
    [qrPrizeValue, onSetLoading],
  );

  useEffect(() => {
    handleOriginalDevicesQuantity(devicesConfig);

    handleSortedDevices(devicesConfig);
  }, [devicesConfig, handleSortedDevices, handleOriginalDevicesQuantity]);

  return (
    <>
      <div className="row">
        <Typography className="cell" size="xl" />
        <Typography className="cell bold" size="xl">
          Cantidad
        </Typography>
        {CALC_COLS.map((c) => (
          <Typography key={c.title} className="cell bold" size="xl">
            {c.title}
          </Typography>
        ))}
      </div>

      {devices &&
        devices.map((d) => {
          const device = devicesTotals.find((dev) => d.id === dev.id);

          return (
            <div key={d.model} className="row">
              <Typography className="cell bold" size="xl">
                {d.model}
              </Typography>
              <TextField
                centered
                className="cell"
                defaultValue={+device?.quantity}
                min={0}
                type="number"
                onChange={(evt) => {
                  device.quantity = evt.target.value;

                  tracking('home', 'calculator-change-quantity');

                  Object.keys(d.prizes).forEach((p) => {
                    device[p.toString()] = +d.prizes[p.toString()] * +device?.quantity;
                  });

                  onSetDevicesTotals([...devicesTotals]);
                }}
              />
              {CALC_COLS.map((c) => (
                <Typography
                  key={`${d.model}-${device?.quantity}-${c.field}`}
                  className="cell"
                  color={+device?.quantity === 0 ? 'disabled' : 'primary'}
                  size="xl"
                >
                  {formatPriceString(device[c.field.toString()]?.toFixed(2), 'MLA')}
                </Typography>
              ))}
            </div>
          );
        })}
    </>
  );
};

DevicesTable.propTypes = {
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  devicesTotals: PropTypes.arrayOf(PropTypes.shape({})),
  qrPrizeValue: PropTypes.number,
  onSetDevicesTotals: PropTypes.func,
  onSetLoading: PropTypes.func,
};

module.exports = DevicesTable;
