// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Card } = require('@andes/card');
const { Text, Title } = require('@andes/typography');

const { MLB } = require('@domain/constants/group-id');

const Incentives = ({ prizesConfigs = [] }) => {
  const { i18n } = useI18n();

  const getConfigsPerGroupId = (id) => prizesConfigs?.find((item) => item?.group_id === id) || {};

  const items = [
    {
      id: 1,
      icon: 'point-mobile.jpg',
      description: `Ganhe R$
      ${
        getConfigsPerGroupId(MLB.APRENDIZ_WITHOUT_PURCHASES).prizes?.device_new_client_cashback || 0
      }
      em até 5 dias úteis por Point que atingir um valor em vendas.`,
      alt: 'maquininha de cartão',
    },
    {
      id: 2,
      icon: 'vendas-mobile.jpg',
      description: i18n.gettext(
        'Gana hasta un {0}% de la facturación mensual de los Point vendidos',
        (
          getConfigsPerGroupId(MLB.TOP_EMPREENDEDOR).prizes
            ?.tpv_percentage_by_transacted_amount_range || 0
        ).toLocaleString('pt-BR'),
      ),
      alt: 'mão recebendo dinheiro',
    },
  ];

  return (
    <div className="incentives-program-wrapper">
      <div className="incentives-program-wrapper__container">
        <Title component="h2" type="title">
          {i18n.gettext('También cuentas con incentivos en dinero')}
        </Title>
        <div className="incentives-program-wrapper__container-items">
          {items.map((item) => (
            <Card key={item.id} shadow="elevated">
              <Image alt={item.alt} src={item.icon} width={70} />
              <div>
                {item.title && (
                  <Title component="h3" size="s">
                    {item.title}
                  </Title>
                )}
                <Text component="p">{item.description}</Text>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

Incentives.propTypes = {
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      group_id: PropTypes.number,
      prizes: PropTypes.shape({
        device_activation_max_earning: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        device_activation_earning_amount: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
      }),
    }),
  ),
};

module.exports = Incentives;
