// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Button } = require('@andes/button');
const { Pill } = require('@andes/badge');
const { Text } = require('@andes/typography');

const tracking = require('@utils/tracking');

const BASE_CLASS_NAME = 'error-register';

const Error = () => {
  const { onSetApiResponseType, siteId } = useFormContext();
  const { i18n } = useI18n();

  const handleButtonClick = () => {
    tracking('home', 'form-register-error');

    onSetApiResponseType('');
  };

  const renderContentBySiteId = () => {
    const commonAction = {
      onClick: () => handleButtonClick(),
      text: i18n.gettext('Intentar nuevamente'),
    };

    switch (siteId) {
      case 'MLB':
        return {
          title: i18n.pgettext('resellers-landing', 'Algo salió mal'),
          description: i18n.gettext(
            'No fue posible finalizar tu registro. Por favor, inténtalo de nuevo o vuelve más tarde.',
          ),
          action: commonAction,
        };

      case 'MLC':
      default:
        return {
          title: i18n.gettext('Ocurrió un error'),
          description: i18n.gettext(
            'No fue posible completar tu registro. Por favor, intenta nuevamente o vuelve más tarde.',
          ),
          action: commonAction,
        };
    }
  };

  const { action, description, title } = renderContentBySiteId();

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__icon`}>
        <Pill color="red" contentType="icon" />
      </div>
      <div className={`${BASE_CLASS_NAME}__texts`}>
        <Text component="h2" weight="semibold">
          {title}
        </Text>
        <Text color="secondary" component="p">
          {description}
        </Text>
      </div>
      <Button fullWidth onClick={action.onClick}>
        {action.text}
      </Button>
    </div>
  );
};

module.exports = Error;
