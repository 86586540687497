const React = require('react');
const PropTypes = require('prop-types');

const { useLandingContext } = require('@context/landing-context');

const { Card } = require('@andes/card');
const { Title, Text } = require('@andes/typography');

const BASE_CLASS_NAME = 'advantages-card';

const AdvantagesCard = ({ asset, color, icon, legend, title }) => {
  const { isMobile } = useLandingContext();

  const hasContent = color || icon || legend || title;

  const textColor = ['dark', 'dark-blue'].includes(color) ? 'inverted' : 'primary';

  if (!hasContent && !asset) {
    return null;
  }

  if (!hasContent) {
    return (
      <Card className={BASE_CLASS_NAME}>
        <div className={`${BASE_CLASS_NAME}__asset`}>{asset}</div>
      </Card>
    );
  }

  return (
    <Card className={BASE_CLASS_NAME} data-type={color}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <div className={`${BASE_CLASS_NAME}__icon`}>{icon}</div>
        <div className={`${BASE_CLASS_NAME}__legend`}>
          <Text color={textColor} size={isMobile ? 'xs' : 'm'} weight="semibold">
            {legend}
          </Text>
        </div>
        <div className={`${BASE_CLASS_NAME}__title`}>
          <Title color={textColor} component="h2" size={isMobile ? 'm' : 'xl'}>
            {title}
          </Title>
        </div>
      </div>
    </Card>
  );
};

AdvantagesCard.propTypes = {
  asset: PropTypes.node,
  color: PropTypes.oneOf(['dark', 'dark-blue', 'light-blue', 'light-yellow']),
  icon: PropTypes.node,
  legend: PropTypes.string,
  title: PropTypes.string,
};

module.exports = AdvantagesCard;
