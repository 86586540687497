const React = require('react');
const PropTypes = require('prop-types');

const { useRef, useState } = React;

const { useRebrandContext } = require('@context/rebrand-context');

const { Text } = require('@andes/typography');
const Icon = require('../icons');

const BASE_CLASS_NAME = 'accordion';

const Accordion = ({ children, open = false, title }) => {
  const [isOpen, setIsOpen] = useState(open);

  const { colors } = useRebrandContext();

  const contentRef = useRef(null);

  const scrollHeight = contentRef.current?.scrollHeight;
  const maxHeight = scrollHeight ? `${scrollHeight}px` : '100%';

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

      toggleOpen();
    }
  };

  return (
    <div className={BASE_CLASS_NAME} data-open={isOpen}>
      <div
        className={`${BASE_CLASS_NAME}__header`}
        role="button"
        tabIndex={-1}
        onClick={toggleOpen}
        onKeyDown={handleKeyDown}
      >
        <Text weight="semibold">{title}</Text>
        <div className="chevron">
          <Icon color={colors.ANDES_ACCENT_COLOR} name="andes_ArrowDown16" />
        </div>
      </div>
      <div
        ref={contentRef}
        className={`${BASE_CLASS_NAME}__content`}
        style={{
          maxHeight: isOpen ? maxHeight : '0',
        }}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool,
};

module.exports = Accordion;
