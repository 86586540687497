const React = require('react');
const PropTypes = require('prop-types');

const { createContext, useContext } = React;

const LandingContext = createContext(null);

export const useLandingContext = () => {
  return useContext(LandingContext);
};

export const LandingProvider = ({ children, ...props }) => {
  return <LandingContext.Provider value={{ ...props }}>{children}</LandingContext.Provider>;
};

LandingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
      price: PropTypes.number,
      suggested_sale_price: PropTypes.number,
    }),
  ),
  devicesConfig: PropTypes.shape({
    configs: PropTypes.arrayOf(PropTypes.shape({})),
    devices: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  deviceType: PropTypes.string,
  experiments: PropTypes.string,
  groupConfigs: PropTypes.shape({
    frequent_purchases_earning_amount: PropTypes.number,
    frequent_purchases_device_quantity: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
  isWebview: PropTypes.bool,
  prizesConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      group_id: PropTypes.number,
      group_name: PropTypes.string,
      prizes: PropTypes.shape({
        device_activation_earning_amount: PropTypes.number,
        device_activation_max_earning: PropTypes.number,
        max_prize_amount_per_month: PropTypes.number,
        min_investment: PropTypes.number,
        new_level_bonus_amount: PropTypes.number,
        tpv_max_earn_prize: PropTypes.number,
        tpv_percentage_earn_prize: PropTypes.number,
      }),
    }),
  ),
  prizesSummary: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.number,
      groupName: PropTypes.string,
      prizes: PropTypes.shape({
        activationEarnings: PropTypes.string,
        investment: PropTypes.string,
        mostValuableDeviceMinBundle: PropTypes.number,
        mostValuableDeviceModel: PropTypes.string,
        newClientEarnings: PropTypes.string,
        salesMarginEarnings: PropTypes.string,
        totalEarnings: PropTypes.string,
      }),
    }),
  ),
  siteId: PropTypes.string.isRequired,
  traficOrigin: PropTypes.string,
  userData: PropTypes.shape({
    blocked: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    isReseller: PropTypes.bool,
    logged: PropTypes.bool,
    phone: PropTypes.string,
    pictureUrl: PropTypes.string,
  }),
};
