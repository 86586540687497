// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { Form } = require('@andes/form');

const Recaptcha = require('@components/recaptcha');
const tracking = require('@utils/tracking');
const Tyc = require('@components/tyc');

const FORM_STATUS = require('@domain/constants/form-status');

const useActions = require('./hooks/use-actions');

const Actions = ({ apiResponseType, errors, onSetApiResponseType, user, traficOrigin }) => {
  const {
    buttons,
    counter,
    isDisabledButton,
    register,
    setDisabledButton,
    setTYC,
    showRecaptchaError,
    tyc,
  } = useActions({ apiResponseType, errors, onSetApiResponseType, user, traficOrigin });

  const hasToAgreeTyc = !user.isReseller && !apiResponseType;
  const hasToValidateRecaptcha = !user.logged && hasToAgreeTyc;

  return (
    <Card className="form__card" paddingsize={32}>
      <div className="form__footer">
        {hasToAgreeTyc && (
          <Tyc
            isChecked={tyc}
            link="https://www.mercadopago.com.ar/ayuda/5248"
            onHandleChange={() => {
              setTYC(!tyc);
              tracking('home', 'click-on-terms-and-conditions');
            }}
          />
        )}
        <Form
          onSubmit={(e) => {
            setDisabledButton(true);
            register(e);
          }}
        >
          {hasToValidateRecaptcha && (
            <Recaptcha
              errors={showRecaptchaError ? ['Completá el reCAPTCHA.'] : []}
              languageId="es-419"
            />
          )}
          {buttons?.primary?.text && (
            <Button
              fullWidth
              disabled={
                [FORM_STATUS.SUCCESS, FORM_STATUS.RESEND_EMAIL].includes(apiResponseType)
                  ? counter > 0
                  : isDisabledButton
              }
              modifier="filled"
              type={buttons.primary?.onClick ? 'button' : 'submit'}
              onClick={() => {
                tracking('home', buttons.primary.tracking);

                if (buttons.primary?.onClick) buttons.primary.onClick();
              }}
            >
              {buttons?.primary?.text(counter)}
            </Button>
          )}
          {buttons?.secondary?.text && (
            <Button
              fullWidth
              className="mt-8"
              hierarchy={buttons?.secondary?.hierarchy || 'transparent'}
              onClick={() => {
                tracking('home', buttons.secondary.tracking);

                buttons.secondary.onClick();
              }}
            >
              {buttons.secondary?.text()}
            </Button>
          )}
        </Form>
      </div>
    </Card>
  );
};

Actions.defaultProps = {
  errors: [],
  user: {
    email: '',
    hasLeader: false,
    logged: false,
    name: '',
    phone: '',
    province: '',
    userId: 0,
    isReseller: false,
  },
  traficOrigin: '',
};

Actions.propTypes = {
  apiResponseType: PropTypes.oneOf([
    FORM_STATUS.ERROR,
    FORM_STATUS.IS_RESELLER,
    FORM_STATUS.PENDING,
    FORM_STATUS.SUCCESS,
    '',
  ]),
  errors: PropTypes.arrayOf(PropTypes.string),
  onSetApiResponseType: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
    hasLeader: PropTypes.bool,
    logged: PropTypes.bool,
    name: PropTypes.string,
    phone: PropTypes.string,
    province: PropTypes.string,
    userId: PropTypes.number,
    isReseller: PropTypes.bool,
  }),
  traficOrigin: PropTypes.string,
};

module.exports = Actions;
