const React = require('react');
const PropTypes = require('prop-types');

const { Text, Title } = require('@andes/typography');

const Video = ({ siteId }) => {
  const isMLM = siteId === 'MLM';

  return (
    <div className={`resellers-info ${siteId?.toLowerCase()}`}>
      <Title component="h3" size="l" type="title">
        {isMLM
          ? 'Otros representantes ya lo están logrando'
          : 'Hacé que tu negocio llegue a donde vos querés'}
      </Title>
      {!isMLM && (
        <Text component="p" size="xl">
          Conocé la experiencia de otros revendedores.
        </Text>
      )}
      <div className="resellers-content">
        <iframe
          // eslint-disable-next-line react/no-unknown-property
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          className="youtube"
          frameBorder="0"
          loading="lazy"
          src={
            isMLM
              ? 'https://www.youtube.com/embed/XIw0K_5jAKc'
              : 'https://www.youtube.com/embed/WefXgunDoQA'
          }
          title="youtube"
        />
      </div>
    </div>
  );
};

Video.propTypes = {
  siteId: PropTypes.string,
};

module.exports = Video;
