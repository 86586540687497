// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { Card } = require('@andes/card');
const { Typography } = require('@andes/typography');

const { formatterPrice } = require('@utils/formatter');

const { CALC_COLS } = require('./configs');

const Footer = ({ devicesTotals }) => {
  const getCalc = React.useCallback(() => {
    const totalDevices = devicesTotals?.reduce((res, d) => +res + +d.quantity, 0);

    let totalUsePrizes = 0;
    let totalActivationPrizes = 0;
    let totalProfit = 0;
    let totalPrice = 0;

    if (devicesTotals) {
      devicesTotals.forEach((d) => {
        totalPrice += d.price;

        CALC_COLS.forEach((calc) => {
          if (calc.type === 'prize' && calc.field === 'usage') {
            totalUsePrizes += +d[calc.field.toString()];
          }
          if (calc.type === 'prize' && calc.field === 'activation') {
            totalActivationPrizes += +d[calc.field.toString()];
          }
          if (calc.type === 'profit') {
            totalProfit += +d[calc.field.toString()];
          }
        });
      });
    }

    const totalGanacia = totalUsePrizes + totalActivationPrizes + totalProfit;

    return {
      totalPrice,
      totalDevices,
      totalUsePrizes,
      totalActivationPrizes,
      totalProfit,
      totalGanacia,
    };
  }, [devicesTotals]);

  const summary = getCalc();

  return (
    <>
      <div className="row row-total">
        <Typography className="cell bold" size="xl">
          Total
        </Typography>
        <Typography className="cell bold" size="xl">
          {summary.totalDevices}
        </Typography>
        <Typography className="cell bold" size="xl">
          {formatterPrice(summary.totalUsePrizes, 'MLM')}
        </Typography>
        <Typography className="cell bold" size="xl">
          {formatterPrice(summary.totalActivationPrizes, 'MLM')}
        </Typography>
        <Typography className="cell bold" size="xl">
          {formatterPrice(summary.totalProfit, 'MLM')}
        </Typography>
      </div>

      <Card className="calculator__total">
        <div>
          <Typography component="p">Monto a invertir</Typography>
          <Typography component="h3" type="title">
            {formatterPrice(summary.totalPrice, 'MLM')}
          </Typography>
        </div>
        <div>
          <Typography component="p">Ganancia</Typography>
          <Typography component="h3" type="title">
            {formatterPrice(summary.totalGanacia, 'MLM')}
          </Typography>
        </div>
      </Card>
    </>
  );
};

Footer.defaultProps = {
  devicesTotals: [],
};

Footer.propTypes = {
  devicesTotals: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number,
      price: PropTypes.number,
      usage: PropTypes.number,
      activation: PropTypes.number,
      profit: PropTypes.number,
    }),
  ),
};

module.exports = Footer;
