// i18nLocalesOverride:['es-AR']

const React = require('react');
const PropTypes = require('prop-types');

const { useState, useCallback } = React;

const { Dropdown, DropdownItem } = require('@andes/dropdown');
const { TextField } = require('@andes/textfield');
const MaskTextField = require('@andes/masktextfield');

const { formatPhoneNumberMLA } = require('../../../../../utils/formatter');
const { phoneMLA } = require('../../../../../utils/constants/phone');
const { searchUser } = require('../../../../../services/user');
const { provinces } = require('../../utils/provinces');

const UnloggedForm = ({
  errors,
  hasRGR,
  onSetEmail,
  onSetErrors,
  onSetName,
  onSetPhone,
  onSetProvince,
  province,
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const [inputModifier, setInputModifier] = useState('default');

  const digitsCounterForAreaCode = String(province).length;

  phoneMLA.mask = formatPhoneNumberMLA(digitsCounterForAreaCode);

  const fetchUser = useCallback(
    async (email) => {
      if (hasRGR) {
        const userResp = await searchUser(email);

        if (!userResp) {
          onSetErrors(errors.filter((er) => er !== 'HAS_MAIL') || []);
          setInputMessage('');
          setInputModifier('default');

          return;
        }

        setInputMessage(userResp ? 'Este e-mail tiene cuenta en Mercado Pago.' : '');
        setInputModifier(userResp ? 'error' : 'default');
        onSetErrors([...errors, 'HAS_MAIL']);
      }
    },
    [hasRGR, onSetErrors, errors],
  );

  const validateEmail = useCallback(
    (event) => {
      const emailInput = event.target.value;

      if (emailInput.length <= 0 || emailInput.length <= 6 || emailInput.indexOf('@') <= 0) {
        onSetErrors([...errors, 'MAIL']);

        return;
      }

      onSetErrors(errors.filter((er) => er !== 'MAIL') || []);
    },
    [errors, onSetErrors],
  );

  const validateName = useCallback(
    (event) => {
      const target = event.target.value;

      if (target.length === 0 || target.length <= 6 || !target.includes(' ')) {
        onSetErrors([...errors, 'NAME']);
      } else if (errors.includes('NAME')) {
        onSetErrors(errors.filter((er) => er !== 'NAME') || []);
      }

      onSetName(target);
    },
    [errors, onSetErrors, onSetName],
  );

  const validatePhone = useCallback(
    (event) => {
      const field = event.target.value.replace(/\D+/g, '');

      if (field.length === 0 || field.length < 10) {
        onSetErrors([...errors, 'PHONE']);
      } else if (errors.includes('PHONE')) {
        onSetErrors(errors.filter((er) => er !== 'PHONE') || []);
      }

      onSetPhone(event.target.value);
    },
    [errors, onSetErrors, onSetPhone],
  );

  return (
    <div className="form__fields">
      <TextField
        helper={errors.includes('NAME') ? 'Revisa tu nombre y apellido.' : ''}
        label="Nombre y apellido"
        modifier={errors.includes('NAME') ? 'error' : 'default'}
        onChange={(event) => validateName(event)}
      />
      <TextField
        helper={errors.includes('MAIL') ? 'Revisa tu e-mail.' : inputMessage}
        label="E-mail"
        modifier={errors.includes('MAIL') ? 'error' : inputModifier}
        onBlur={async (event) => {
          const emailInput = event.target.value;

          if (emailInput.length <= 0 && !errors.includes('MAIL')) {
            return;
          }

          await fetchUser(emailInput);

          onSetEmail(emailInput);
        }}
        onChange={(event) => validateEmail(event)}
      />
      <div className="form__fields-content">
        <Dropdown
          label="Cód. Provincia"
          menuAlignment="bottom"
          menuMaxHeight={222}
          placeholder="Seleccioná"
          type="form"
          onChange={(e, value) => {
            onSetPhone(value);
            onSetProvince(value);
          }}
        >
          {provinces.map((item) => (
            <DropdownItem key={item.code} title={item.name} value={item.code} />
          ))}
        </Dropdown>
        <MaskTextField label="Teléfono" onChange={(e) => validatePhone(e)} {...phoneMLA} />
      </div>
    </div>
  );
};

UnloggedForm.defaultProps = {
  errors: [],
  hasRGR: false,
  province: '',
  onSetErrors: () => {},
  onSetEmail: () => {},
  onSetName: () => {},
  onSetPhone: () => {},
  onSetProvince: () => {},
};

UnloggedForm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.any),
  hasRGR: PropTypes.bool,
  province: PropTypes.string,
  onSetErrors: PropTypes.func,
  onSetEmail: PropTypes.func,
  onSetName: PropTypes.func,
  onSetPhone: PropTypes.func,
  onSetProvince: PropTypes.func,
};

module.exports = UnloggedForm;
