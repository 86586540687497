const API_RESPONSE_TYPE = require('../constants/api-response-type');

const getResponseType = (user) => {
  const { blocked, isReseller } = user;

  if (blocked) {
    return API_RESPONSE_TYPE.ERROR_ACCOUNT_BLOCKED;
  }

  if (isReseller) {
    return API_RESPONSE_TYPE.ACCOUNT_ALREADY_REGISTERED;
  }

  return '';
};

module.exports = getResponseType;
