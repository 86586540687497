// i18nLocalesOverride:['es-AR']

const React = require('react');

const { useEffect, useState } = React;

const { useI18n } = require('nordic/i18n');

const { getExternalURLs } = require('@utils/navigation');
const { resendConfirmationEmail } = require('@services/leads');
const { saveLeads } = require('@services/selfpurchase');
const { trackingEvent } = require('@utils/tracking/tracking-events');

const FORM_STATUS = require('@domain/constants/form-status');

const useActions = ({ apiResponseType, errors, onSetApiResponseType, traficOrigin, user }) => {
  const { i18n } = useI18n();

  const COUNTER_TIME = 30;
  const [counter, setCounter] = useState(COUNTER_TIME);
  const [disabledButton, setDisabledButton] = useState(false);

  const [tyc, setTYC] = useState(true);
  const [leadId, setLeadId] = useState();
  const [showRecaptchaError, setShowRecaptchaError] = useState(false);

  const externalUrls = getExternalURLs('MLA', traficOrigin);

  const isDisabledButton =
    disabledButton ||
    !tyc ||
    errors.length ||
    user.name === '' ||
    user.email === '' ||
    (!user.logged ? user.phone === '' || user.province === '' : null);

  const sanitizeTraficOrigin = () => {
    let traficOriginParams = traficOrigin;

    if (traficOriginParams.includes('utm_position=form')) {
      const formParamRegex = /&utm_position=form/g;
      const traficOriginWithoutFormParam = traficOriginParams?.replace(formParamRegex, '');

      traficOriginParams = traficOriginWithoutFormParam;
    }

    return traficOriginParams;
  };

  const logout = () => {
    const landingUrl = encodeURIComponent(
      `https://www.mercadopago.com.ar/revendedores?${traficOrigin?.replace('&', '')}`,
    );

    const url = `https://www.mercadolibre.com/jms/mla/lgz/login?platform_id=MP&go=${landingUrl}`;

    window.open(url, '_self');
  };

  const handleSaveRegisterResponse = (response) => {
    switch (true) {
      case response?.is_reseller && !user.logged:
        trackingEvent('user_is_reseller_but_not_logged');

        setDisabledButton(false);

        onSetApiResponseType(FORM_STATUS.IS_RESELLER);

        return;

      case response?.is_reseller:
        trackingEvent('user_is_reseller_and_logged');

        window.open(externalUrls.MICROSITIO, '_self');

        return;

      case response?.is_new && user.logged:
        trackingEvent('new_register_with_user_logged');

        window.open(externalUrls.ACCOUNT_CONFIRMATION, '_self');

        return;

      case response?.is_new && response?.has_mp_account:
        trackingEvent('new_register_with_mp_account_already_created');

        setDisabledButton(false);

        onSetApiResponseType(FORM_STATUS.SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED);

        return;

      case !response?.is_new && response?.has_mp_account:
        trackingEvent('new_register_with_pending_account');

        setDisabledButton(false);

        onSetApiResponseType(FORM_STATUS.SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED);

        return;

      default:
        trackingEvent('new_register_with_new_account');

        setDisabledButton(false);

        onSetApiResponseType(FORM_STATUS.SUCCESS);
    }
  };

  const saveRegister = async (token) => {
    try {
      const traficOriginParams = sanitizeTraficOrigin();

      const phone = user.phone?.replace(/\D+/g, '');

      const resp = await saveLeads({
        name: user.name,
        email: user.email,
        phone,
        trafic_origin: traficOriginParams,
        recaptcha_token: token,
        phone_details: {
          area_code: user?.province,
          number: phone?.slice(String(user?.province)?.length || 0),
        },
      });

      const response = resp?.data || {};

      setLeadId(response?.id);

      handleSaveRegisterResponse(response);
    } catch (error) {
      trackingEvent('new_register_with_error');

      setDisabledButton(false);
    }
  };

  const register = async (e) => {
    e.preventDefault();

    if (user.hasLeader) {
      window.location.href = `${externalUrls.MICROSITIO}/lider/${user?.userId}`;

      return;
    }

    if (user.isReseller) {
      window.location.href = `${externalUrls.MICROSITIO}`;

      return;
    }

    // TODO: Check when user is pending

    if (errors?.length) {
      setDisabledButton(false);

      return;
    }

    const token = e.target[1]?.value;

    if (!token && !user.logged) {
      setShowRecaptchaError(true);

      setDisabledButton(false);

      return;
    }

    trackingEvent('form-register');

    await saveRegister(token);
  };

  const handleResendEmail = async () => {
    try {
      await resendConfirmationEmail(false, leadId);

      setCounter(COUNTER_TIME);

      onSetApiResponseType(FORM_STATUS.RESEND_EMAIL);
    } catch (err) {
      setDisabledButton(false);
    }
  };

  const registerWithOtherAccount = () => {
    return user.logged ? logout() : window.location.reload();
  };

  const buttonByRegisterType = () => {
    const resellerButtonLabel = user.hasLeader
      ? i18n.gettext('Consultar datos de mi líder')
      : i18n.gettext('Ir al portal');
    const loggedButtonLabel = user.isReseller
      ? resellerButtonLabel
      : i18n.gettext('Quiero ser revendedor');

    const trackingReseller = user.hasLeader
      ? 'form-register-reseller-go-to-portal-rgr'
      : 'form-register-reseller-go-to-portal';
    const trackingLogged = user.isReseller
      ? trackingReseller
      : 'form-register-i-want-to-be-a-reseller';

    switch (apiResponseType) {
      case FORM_STATUS.SUCCESS_HAS_ACCOUNT_AND_NOT_LOGGED:
        return {
          primary: {
            text: () => i18n.gettext('Acceder a la cuenta'),
            onClick: () => window.open(externalUrls.ACCOUNT_CONFIRMATION, '_self'),
            tracking: 'form-register-account-confirmation-continue-button',
          },
        };

      case FORM_STATUS.IS_RESELLER:
        return {
          primary: {
            text: () => i18n.gettext('Ir al portal'),
            onClick: () => window.open(externalUrls.MICROSITIO, '_self'),
            tracking: 'form-register-reseller-go-to-portal-not-logged',
          },
          secondary: {
            text: () => i18n.gettext('Volver al formulario'),
            onClick: () => registerWithOtherAccount(),
            tracking: 'form-register-reseller-return-to-form-not-logged',
          },
        };

      case FORM_STATUS.SUCCESS:
        return {
          primary: {
            text: (counterValue) =>
              counterValue > 0
                ? i18n.gettext('Reenviar e-mail en {0}', `00:${counterValue}`)
                : i18n.gettext('Reenviar e-mail'),
            onClick: () => handleResendEmail(),
            tracking: 'form-register-success-create-account',
          },
          secondary: {
            text: () => i18n.gettext('Registrar otro e-mail'),
            onClick: () => registerWithOtherAccount(),
            tracking: 'form-register-success-register-with-other-account',
          },
        };

      case FORM_STATUS.RESEND_EMAIL:
        return {
          primary: {
            text: (counterValue) =>
              counterValue > 0
                ? i18n.gettext('Reenviar e-mail en {0}', `00:${counterValue}`)
                : i18n.gettext('Reenviar e-mail'),
            onClick: () => handleResendEmail(),
            tracking: 'form-register-pending-resend-email',
          },
          secondary: {
            text: () => i18n.gettext('Registrar otro e-mail'),
            onClick: () => registerWithOtherAccount(),
            tracking: 'form-register-success-register-with-other-account',
          },
        };

      default:
        return {
          primary: {
            text: () => (user.logged ? loggedButtonLabel : i18n.gettext('Registrar datos')),
            tracking: user.logged ? trackingLogged : 'form-register-i-want-to-be-a-reseller',
          },
          ...(!user.logged && {
            secondary: {
              text: () => i18n.gettext('Ya tengo cuenta'),
              onClick: () => logout(),
              tracking: 'form-register-without-account',
            },
          }),
        };
    }
  };

  const buttons = buttonByRegisterType();

  useEffect(() => {
    const showCounter = [FORM_STATUS.SUCCESS, FORM_STATUS.RESEND_EMAIL].includes(apiResponseType);

    if (!showCounter) {
      return () => {};
    }

    const timer = setInterval(
      () =>
        setCounter((state) => {
          const newState = state - 1;

          return String(newState).padStart(2, '0');
        }),
      1000,
    );

    return () => clearInterval(timer);
  }, [apiResponseType]);

  return {
    apiResponseType,
    buttons,
    counter,
    isDisabledButton,
    register,
    setDisabledButton,
    setTYC,
    showRecaptchaError,
    tyc,
  };
};

module.exports = useActions;
