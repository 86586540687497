// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Text, Title } = require('@andes/typography');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const CardDivider = require('@components/card-divider');

const Benefits = ({ isMobile }) => {
  const { i18n } = useI18n();

  const imageName = isMobile ? 'benefits-mobile' : 'benefits-desktop';

  const items = [
    {
      title: i18n.gettext('Flexibilidad para decidir cuándo y cómo vender.'),
    },
    {
      title: i18n.gettext('Capacitación para ayudarte a crecer en el programa.'),
    },
    {
      title: i18n.gettext('Envío gratis en tus compras de lectores Point.'),
    },
    {
      title: i18n.gettext('Point con descuento para que ganes con la reventa.'),
    },
  ];

  const imageComponent = (
    <div className="benefits-wrapper__container-image">
      <Image
        alt="Vantagens de ser um revendedor de Mercado Pago"
        src={`${imageName}.webp`}
        srcFallback={`${imageName}.png`}
        width={isMobile ? 353 : 510}
      />
    </div>
  );

  return (
    <div className="benefits-wrapper">
      <div className="benefits-wrapper__container">
        <div>
          <CardDivider />
          <Title component="h2" type="title">
            {i18n.gettext('Ventajas de ser un revendedor de Mercado Pago')}
          </Title>
          {isMobile && imageComponent}
          <div>
            {items.map((item) => (
              <div key={item.title} className="benefits-wrapper__container-item">
                <FeedbackPositive24 color="#009EE3" />
                <Text>{item.title}</Text>
              </div>
            ))}
          </div>
        </div>
        {!isMobile && imageComponent}
      </div>
    </div>
  );
};

Benefits.propTypes = {
  isMobile: PropTypes.bool,
};

module.exports = Benefits;
