// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useEffect, useRef, useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Title } = require('@andes/typography');
const { Button } = require('@andes/button');

const { useRebrandContext } = require('@context/rebrand-context');

const BASE_CLASS_NAME = 'video';
const COMPONENT_ID = 'video';
const VIDEO_ID = 'me-i-y5YgtA';

const Video = () => {
  const [isApiReady, setIsApiReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const playerRef = useRef(null);
  const videoRef = useRef(null);

  const { i18n } = useI18n();

  const { assetsPath } = useRebrandContext();

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const loadYouTubeAPI = () => {
    if (window?.YT?.Player) {
      setIsApiReady(true);

      return;
    }

    const scriptTag = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];

    scriptTag.src = 'https://www.youtube.com/player_api';
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

    window.onYouTubePlayerAPIReady = () => {
      setIsApiReady(true);
    };
  };

  useEffect(() => {
    loadYouTubeAPI();

    if (isApiReady && isPlaying && videoRef.current) {
      playerRef.current = new window.YT.Player(videoRef.current, {
        videoId: VIDEO_ID,
        events: {
          onReady: (event) => event.target.playVideo(),
        },
      });
    }
  }, [isApiReady, isPlaying]);

  return (
    <div className={BASE_CLASS_NAME} id={COMPONENT_ID}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <Title component="h1" size="l">
          {i18n.gettext('Otros revendedores ya lo están logrando')}
        </Title>
        {isPlaying ? (
          <div ref={videoRef} className={`${BASE_CLASS_NAME}__player`} id="video" />
        ) : (
          <Button
            alt="Conoce el testimonio de otros revendedores"
            hierarchy="transparent"
            onClick={handlePlayClick}
          >
            <Image
              alt="Mira vídeo de revendedores que ya están logrando"
              src={`${assetsPath}/video-image.webp`}
              srcFallback={`${assetsPath}/video-image.png`}
            />
          </Button>
        )}
      </div>
      <div className={`${BASE_CLASS_NAME}__background`} />
    </div>
  );
};

module.exports = Video;
