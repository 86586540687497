// i18nLocalesOverride:['es-MX']

const React = require('react');
const PropTypes = require('prop-types');

const { Card } = require('@andes/card');
const { Tab, Tabs, TabContent } = require('@andes/tabs');
const { Typography } = require('@andes/typography');

const CardDivider = require('@components/card-divider');
const DevicesTable = require('./devices-table');
const Footer = require('./footer');

const { getDevicesConfigs } = require('@services/devices');

const tracking = require('@utils/tracking');

const Calculator = ({ groups, devicesConfig }) => {
  const [loading, setLoading] = React.useState(true);
  const [configs, setConfigs] = React.useState(devicesConfig);
  const [devicesTotals, setDevicesTotals] = React.useState(undefined);

  const fetchConfigs = React.useCallback(async (level) => {
    const resp = await getDevicesConfigs(level);

    setConfigs({
      devices: resp?.data?.devices,
      configs: resp?.data?.configs,
      devicesOrder: resp?.data?.devicesOrder,
    });
  }, []);

  return (
    <div className="calculator__wrapper" data-loading={loading}>
      <Card className="calculator__container">
        <CardDivider />
        <div className="calculator">
          <Typography component="h3" size="l" type="title">
            Calcula tus ganancias
          </Typography>
          <Typography size="xl">Selecciona la cantidad de terminales para conocerlas.</Typography>

          <div className="calculator__content">
            <Tabs className="demo-tabs" srLabel="Default tabs">
              {groups.map((tab) => (
                <Tab
                  key={tab.id}
                  title={tab.name}
                  onClick={async () => {
                    tracking('home', `calculator-tab-${tab.name}`);

                    await fetchConfigs(tab.id);
                  }}
                >
                  <TabContent>
                    <Card className="calculator__card">
                      <DevicesTable
                        devicesConfig={configs}
                        devicesTotals={devicesTotals}
                        onSetDevicesTotals={setDevicesTotals}
                        onSetLoading={setLoading}
                      />
                      <Footer devicesTotals={devicesTotals} />
                    </Card>
                  </TabContent>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </Card>
    </div>
  );
};

Calculator.defaultProps = {
  data: {},
  devicesConfig: {},
};

Calculator.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  devicesConfig: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    configs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

module.exports = Calculator;
