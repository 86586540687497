// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useCallback, useRef } = React;

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { Form } = require('@andes/form');
const { Text } = require('@andes/typography');
const { TextField } = require('@andes/textfield');

const PillAccount = require('../../pill-account');
const Tyc = require('../tyc');

const helperText = require('../../utils/field-helpers');
const masks = require('@andes/textfield/build/masks');
const tracking = require('@utils/tracking');
const useMask = require('@andes/textfield/build/hooks/useMask');
const validators = require('../../utils/validators');

const BASE_CLASS_NAME = 'logged-user';

const LoggedUser = () => {
  const { i18n } = useI18n();

  const {
    errors,
    isWebview,
    onSetErrors,
    onSetPhone,
    onSetTyc,
    showErrors,
    siteId,
    user,
    validateForm,
  } = useFormContext();

  const hasPhoneError = showErrors && errors?.includes('PHONE');

  const phoneFieldRef = useRef();
  const username = user?.name?.split(' ')[0] || user?.name;

  useMask(phoneFieldRef, { maskOptions: masks.BR.MOBILE });

  const fieldInteractionTracking = (field) => tracking('home', `form-${field}-interaction`);

  const handleError = useCallback(
    (action = 'add', key) => {
      let newErrors = errors;

      if (action === 'remove') {
        newErrors = errors.length ? errors.filter((er) => er !== key) : [];
      } else {
        newErrors.push(key);
      }

      onSetErrors(newErrors);
    },
    [errors, onSetErrors],
  );

  const validatePhone = useCallback(
    (event) => {
      const { value } = event.target;

      const isValid = validators.validatePhone(value, siteId);

      handleError(isValid ? 'remove' : 'add', 'PHONE');

      onSetPhone(value);
    },
    [handleError, onSetPhone, siteId],
  );

  const handleChangeTyc = () => {
    handleError('remove', 'TYC');

    onSetTyc((state) => !state);

    tracking('home', 'click-on-terms-and-conditions');
  };

  const submit = async (e) => {
    tracking('home', 'form-register-i-want-to-be-a-reseller');

    validateForm(e);
  };

  const renderContentBySiteId = () => {
    const commonProps = {
      actionText: i18n.gettext('Registrarse'),
      title: isWebview
        ? i18n.pgettext('resellers-landing', '¡Hola, {0}!', username)
        : i18n.gettext('Regístrate en el programa'),
    };

    switch (siteId) {
      case 'MLB':
        return {
          ...commonProps,
          description: i18n.gettext('Completa los campos para participar en el programa.'),
          phoneField: {
            prefix: '+55',
            helper: hasPhoneError ? i18n.gettext(helperText.helperForPhoneField(user.phone)) : '',
            label: i18n.gettext('Número de WhatsApp'),
            placeholder: '(99) 99999-9999',
            srLabel: i18n.gettext('Número de WhatsApp'),
          },
        };

      case 'MLC':
      default:
        return {
          ...commonProps,
          description: i18n.gettext('Acepta las condiciones para participar en el programa.'),
        };
    }
  };

  const { actionText, description, phoneField, title } = renderContentBySiteId();

  return (
    <Card className={BASE_CLASS_NAME}>
      <Text component="h2" weight="semibold">
        {title}
      </Text>
      <Text color="secondary">{description}</Text>

      {!isWebview && <PillAccount />}

      <Form onSubmit={submit}>
        {phoneField && (
          <TextField
            ref={phoneFieldRef}
            defaultValue={user.phone}
            helper={phoneField.helper}
            label={phoneField.label}
            modifier={hasPhoneError ? 'error' : undefined}
            placeholder={phoneField.placeholder}
            prefix={phoneField.prefix}
            srLabel={phoneField.srLabel}
            onBlur={({ target }) => {
              if (target.value) {
                fieldInteractionTracking('phone');
              }
            }}
            onChange={validatePhone}
          />
        )}

        <Tyc isChecked={user.tyc} onHandleChange={handleChangeTyc} />

        <Button fullWidth type="submit">
          {actionText}
        </Button>
      </Form>
    </Card>
  );
};

module.exports = LoggedUser;
