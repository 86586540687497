const React = require('react');

const Feedbacks = require('../feedbacks');
const LoggedUser = require('./logged-user');
const UnloggedUser = require('./unlogged-user');

const { useFormContext } = require('../context/use-form-context');

const Register = () => {
  const { apiResponseType, user } = useFormContext();

  const renderComponent = () => {
    if (apiResponseType !== '') {
      return <Feedbacks />;
    }

    if (user?.logged) {
      return <LoggedUser />;
    }

    return <UnloggedUser />;
  };

  return renderComponent();
};

module.exports = Register;
