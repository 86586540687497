// i18nLocalesOverride:['es-CL', 'pt-BR']

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useFormContext } = require('../../context/use-form-context');

const { Button } = require('@andes/button');
const { Text } = require('@andes/typography');
const PillAccount = require('../../pill-account');

const { getExternalURLs } = require('@utils/navigation');
const tracking = require('@utils/tracking');

const BASE_CLASS_NAME = 'account-already-registered';

const AccountAlreadyRegistered = () => {
  const { user, siteId, traficOrigin, isWebview, onSetApiResponseType } = useFormContext();
  const { email, logged } = user;
  const { i18n } = useI18n();

  const externalUrls = getExternalURLs(siteId, traficOrigin);

  const registerWithOtherAccount = () => onSetApiResponseType('');

  const redirectToDashboard = () => {
    tracking('home', 'form-register-error-account-duplicated');
    window.open(externalUrls.MICROSITIO, '_self');
  };

  const renderContentBySiteId = () => {
    const commonActions = [
      {
        hierarchy: 'loud',
        onClick: () => redirectToDashboard(),
        text: i18n.gettext('Ir al portal'),
      },
      !logged && {
        hierarchy: 'transparent',
        onClick: () => registerWithOtherAccount(),
        text: i18n.gettext('Volver al formulario'),
      },
    ].filter(Boolean);

    switch (siteId) {
      case 'MLB':
        return {
          title: i18n.gettext('Registro existente'),
          description:
            isWebview || !logged
              ? i18n.gettext(
                  'El e-mail {0} ya está registrado. Accede a tu portal y aprovecha tus beneficios.',
                  email,
                )
              : i18n.gettext(
                  'Ya formas parte del programa. Accede a tu portal y aprovecha los beneficios.',
                ),
          actions: commonActions,
        };

      case 'MLC':
      default:
        return {
          title: i18n.gettext('Ya eres parte del programa'),
          description:
            isWebview || !logged
              ? i18n.gettext(
                  'El e-mail {0} ya está registrado. Accede al portal y aprovecha tus beneficios.',
                  email,
                )
              : i18n.gettext('Accede al portal y aprovecha tus beneficios.'),
          actions: commonActions,
        };
    }
  };

  const { actions, description, title } = renderContentBySiteId();

  return (
    <div className={BASE_CLASS_NAME}>
      <div className={`${BASE_CLASS_NAME}__texts`}>
        <Text component="h2" weight="semibold">
          {title}
        </Text>
        <Text color="secondary" component="p">
          {description}
        </Text>
      </div>
      {!isWebview && logged && <PillAccount />}
      <div className={`${BASE_CLASS_NAME}__actions`}>
        {actions.map(({ hierarchy, onClick, text }) => (
          <Button key={text} fullWidth hierarchy={hierarchy} onClick={onClick}>
            {text}
          </Button>
        ))}
      </div>
    </div>
  );
};

module.exports = AccountAlreadyRegistered;
