// i18nLocalesOverride:['pt-BR']

const React = require('react');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Text, Title } = require('@andes/typography');

const configs = require('./config');

const PaymentMethod = () => {
  const { i18n } = useI18n();

  const { title, text, items } = configs(i18n);

  return (
    <div className="payment-method-wrapper">
      <div className="payment-method-wrapper__container">
        <Title component="h2" type="title">
          {title}
        </Title>
        <div className="payment-method-wrapper__container-items">
          {items.map((item) => (
            <Image key={item.alt} alt={item.alt} src={item.src} title={item.title} />
          ))}
        </div>
        <Text component="h3">{text}</Text>
      </div>
    </div>
  );
};

module.exports = PaymentMethod;
