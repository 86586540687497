// i18nLocalesOverride:['pt-BR']

const { MLB } = require('@domain/constants/group-id');

module.exports = (i18n, prizesConfigs, isMobile) => {
  const getMinInvestment = (groupId) => {
    const findGroup = prizesConfigs.find((group) => group.group_id === groupId);

    return (+findGroup?.prizes?.min_investment || 0).toLocaleString('pt-BR');
  };

  function getMaxPrizeAmount(groupId) {
    const findGroup = prizesConfigs.find((group) => group.group_id === groupId);

    const maxPrizeAmount = (+findGroup?.prizes?.max_prize_amount_per_month || 0).toLocaleString(
      'pt-BR',
    );

    return (+maxPrizeAmount)?.toFixed(0);
  }

  return {
    title: i18n.gettext('Conoce los niveles del programa'),
    items: [
      {
        id: 1,
        icon: isMobile ? 'mobile-aprendiz.webp' : 'desktop-aprendiz.webp',
        iconFallback: isMobile ? 'mobile-aprendiz.png' : 'desktop-aprendiz.png',
        title: i18n.gettext('Aprendiz'),
        description: i18n.jsx.gettext('Compra al menos {0}$ {1} en lectores', {
          tags: {
            0: '<br />',
          },
          replacements: {
            1: getMinInvestment(MLB.APRENDIZ_WITHOUT_PURCHASES),
          },
          wrapper: 'span',
        }),
        aditional: i18n.jsx.gettext('Gana hasta {0}$ {2} mil mensuales{1}', {
          tags: {
            0: '<strong>',
            1: '</strong>',
          },
          replacements: {
            2: getMaxPrizeAmount(MLB.APRENDIZ_WITHOUT_PURCHASES),
          },
          wrapper: 'span',
        }),
        alt: 'uma pessoa em nível aprendiz',
      },
      {
        id: 2,
        icon: isMobile ? 'mobile-especialista.webp' : 'desktop-especialista.webp',
        iconFallback: isMobile ? 'mobile-especialista.png' : 'desktop-especialista.png',
        title: i18n.gettext('Especialista'),
        description: i18n.jsx.gettext('Compra al menos {0}$ {1} en lectores', {
          tags: {
            0: '<br />',
          },
          replacements: {
            1: getMinInvestment(MLB.ESPECIALISTA),
          },
          wrapper: 'span',
        }),
        aditional: i18n.jsx.gettext('Gana hasta {0}$ {2} mil mensuales{1}', {
          tags: {
            0: '<strong>',
            1: '</strong>',
          },
          replacements: {
            2: getMaxPrizeAmount(MLB.ESPECIALISTA),
          },
          wrapper: 'span',
        }),
        alt: 'uma pessoa em nível especialista',
      },
      {
        id: 3,
        icon: isMobile ? 'mobile-empreendedor.webp' : 'desktop-empreendedor.webp',
        iconFallback: isMobile ? 'mobile-empreendedor.png' : 'desktop-empreendedor.png',
        title: i18n.gettext('Emprendedor'),
        description: i18n.jsx.gettext('Compra al menos {0}$ {1} en lectores', {
          tags: {
            0: '<br />',
          },
          replacements: {
            1: getMinInvestment(MLB.EMPREENDEDOR),
          },
          wrapper: 'span',
        }),
        aditional: i18n.jsx.gettext('Gana hasta {0}$ {2} mil mensuales{1}', {
          tags: {
            0: '<strong>',
            1: '</strong>',
          },
          replacements: {
            2: getMaxPrizeAmount(MLB.EMPREENDEDOR),
          },
          wrapper: 'span',
        }),
        alt: 'uma pessoa em nível empreendedor',
      },
      {
        id: 4,
        icon: isMobile ? 'mobile-top-empreendedor.webp' : 'desktop-top-empreendedor.webp',
        iconFallback: isMobile ? 'mobile-top-empreendedor.png' : 'desktop-top-empreendedor.png',
        title: i18n.gettext('Top Emprendedor'),
        description: i18n.jsx.gettext('Compra al menos {0}$ {1} en lectores', {
          tags: {
            0: '<br />',
          },
          replacements: {
            1: getMinInvestment(MLB.TOP_EMPREENDEDOR),
          },
          wrapper: 'span',
        }),
        aditional: i18n.jsx.gettext('Gana hasta {0}$ {2} mil mensuales{1}', {
          tags: {
            0: '<strong>',
            1: '</strong>',
          },
          replacements: {
            2: getMaxPrizeAmount(MLB.TOP_EMPREENDEDOR),
          },
          wrapper: 'span',
        }),
        alt: 'uma pessoa em nível top empreendedor',
      },
    ],
  };
};
