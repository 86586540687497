// i18nLocalesOverride:['es-AR']

const React = require('react');

const { useLandingContext } = require('@context/landing-context');

const Benefits = require('@components/benefits');
const ButtonFixed = require('@components/button-fixed');
const Discover = require('@components/discover');
const FAQList = require('@components/faq');
const Footer = require('@components/footer');
const HeaderFixed = require('@components/header-fixed');
const Hero = require('@components/hero');
const Points = require('@components/points');
const PointsInfo = require('@components/points-info');
const Timeline = require('@components/timeline');
const Video = require('@components/video');

const Calculator = require('./components/calculator');
const Levels = require('./components/levels');
const Register = require('./components/register');

const LandingMla = () => {
  const {
    data,
    devicesConfig,
    deviceType,
    groupConfigs,
    isMobile,
    siteId,
    traficOrigin,
    userData,
  } = useLandingContext();

  const orderedDevices = data?.devices?.sort(
    (a, b) => a?.price_with_discount - b?.price_with_discount,
  );

  return (
    <div>
      <HeaderFixed isReseller={userData?.isReseller} siteId={siteId} />
      <Hero isMobile={isMobile} siteId={siteId} />
      <Register isMobile={isMobile} traficOrigin={traficOrigin} user={userData} />
      <Timeline isMobile={isMobile} siteId={siteId} />
      <Discover siteId={siteId} />
      <Points devices={orderedDevices} isMobile={isMobile} siteId={siteId} />
      <Benefits
        devicesConfig={devicesConfig}
        groupConfigs={groupConfigs}
        isMobile={isMobile}
        siteId={siteId}
      />
      <Calculator devicesConfig={devicesConfig} qrPrizeValue={groupConfigs?.qr_earned_by_seller} />
      <Levels deviceType={deviceType} />
      <Video siteId={siteId} />
      <PointsInfo siteId={siteId} />
      <FAQList devices={orderedDevices} siteId={siteId} />
      <Footer isMobile={isMobile} siteId={siteId} />
      {!userData?.isReseller && (
        <ButtonFixed isLogged={userData?.logged} isMobile={isMobile} siteId={siteId} />
      )}
    </div>
  );
};

module.exports = LandingMla;
