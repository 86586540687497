// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useState } = React;

const { useI18n } = require('nordic/i18n');

const { Title } = require('@andes/typography');

const CardDivider = require('../card-divider');
const Collapsible = require('../collapsible');

const tracking = require('@utils/tracking');

const faqsConfigs = require('./configs');

const FAQList = (props) => {
  const { configs = { devices: [], prizesConfigs: [] }, devices = [], siteId = 'MLA' } = props;

  const { i18n } = useI18n();

  const config = faqsConfigs(i18n, devices, siteId, configs);

  const isCollapsible = siteId === 'MLB';
  const slicedFaqs = config.faqs.slice(0, 5);

  const [faqs, setFaqs] = useState(isCollapsible ? slicedFaqs : config.faqs);
  const [showAllFaqs, setShowAllFaqs] = useState(!isCollapsible);

  const handleRedirectToForm = () => {
    const { deviceType } = typeof window !== 'undefined' && window.__PRELOADED_STATE__;

    const findFormRedirectElement = document.getElementById('form-redirect');

    findFormRedirectElement?.addEventListener('click', () =>
      window.scrollTo(0, deviceType !== 'mobile' ? 550 : 750),
    );
  };

  const handleChangeFaqQuantity = (state) => {
    setFaqs(state ? config.faqs : slicedFaqs);

    tracking('home', `faq-${state ? 'see-more' : 'see-less'}-button`);

    setShowAllFaqs(state);
  };

  return (
    <div className="faq-list">
      <CardDivider />
      <Title component="h2" size="l">
        {config.title}
      </Title>

      <Collapsible items={faqs} onclick={handleRedirectToForm} />

      {isCollapsible && (
        <div className="buttons">
          <div
            className="faq-list__button"
            role="button"
            tabIndex={-1}
            onClick={() => handleChangeFaqQuantity(!showAllFaqs)}
            onKeyDown={() => {}}
          >
            {showAllFaqs ? i18n.gettext('Ocultar preguntas') : i18n.gettext('Cargar más')}
          </div>
        </div>
      )}
    </div>
  );
};

FAQList.propTypes = {
  configs: PropTypes.shape({
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        model: PropTypes.string,
        price: PropTypes.number,
        suggested_sale_price: PropTypes.number,
      }),
    ),
    prizesConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        group_name: PropTypes.string,
        group_id: PropTypes.number,
        prizes: PropTypes.shape({
          device_activation_max_earning: PropTypes.number,
          new_level_bonus_amount: PropTypes.number,
          device_activation_earning_amount: PropTypes.number,
          tpv_percentage_earn_prize: PropTypes.number,
          tpv_max_earn_prize: PropTypes.number,
          max_prize_amount_per_month: PropTypes.number,
        }),
      }),
    ),
  }),
  devices: PropTypes.arrayOf(PropTypes.any),
  siteId: PropTypes.string,
};

module.exports = FAQList;
