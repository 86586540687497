// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Title, Text } = require('@andes/typography');
const Payment = require('@andes/icons/Payment24');

const Marquee = require('react-fast-marquee').default;

const Form = require('@components/form');

const Hero = (props) => {
  const { isMobile, isWebview, traficOrigin, userData } = props;

  const { i18n } = useI18n();

  return (
    <>
      <div className="message-band-container">
        <Marquee autoFill>
          <div className="message-band-content">
            <Payment color="#FFF" />
            <Text color="inverted" weight="semibold">
              Dinheiro na conta em até 5 dias úteis
            </Text>
          </div>
        </Marquee>
      </div>
      <div className="hero-wrapper" data-version="form">
        <div className="hero-wrapper__container">
          <div className="hero-wrapper__container-texts">
            <Title className="hero-wrapper__title" component="h1" size="l" type="title">
              {i18n.gettext('GANA DINERO EXTRA VENDIENDO LECTORES')}
            </Title>
            {isMobile && (
              <Image
                preload
                alt="Homem de cabelo raspado, com um sorriso no rosto, de braços cruzados. Na sua frente estão as Point do Mercado Pago. Da esqueda para a direita:Point Air,Point Mini NFC 2, Point Smart e Point Pro 2."
                lazyload="off"
                src="hero-renda-na-mao-mobile.webp"
                srcFallback="hero-renda-na-mao-mobile.png"
                title="Homem de cabelo raspado, com um sorriso no rosto, de braços cruzados. Na sua frente estão as Point do Mercado Pago. Da esqueda para a direita:Point Air,Point Mini NFC 2, Point Smart e Point Pro 2."
              />
            )}
            <Form isWebview={isWebview} siteId="MLB" traficOrigin={traficOrigin} user={userData} />
          </div>
        </div>
      </div>
    </>
  );
};

Hero.propTypes = {
  isMobile: PropTypes.bool,
  isWebview: PropTypes.bool,
  traficOrigin: PropTypes.string,
  userData: PropTypes.shape({
    blocked: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    isReseller: PropTypes.bool,
    leaderId: PropTypes.number,
    phone: PropTypes.string,
  }),
};

module.exports = Hero;
