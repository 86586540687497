// i18nLocalesOverride:['pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Text, Title } = require('@andes/typography');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const CardDivider = require('@components/card-divider');

const Background = require('./background');

const PointBenefits = ({ isMobile }) => {
  const { i18n } = useI18n();

  const imageName = isMobile
    ? 'modelos-de-maquininhas-point-mobile'
    : 'modelos-de-maquininhas-point';

  const items = [
    {
      title: i18n.gettext('Garantía de 3 años.'),
    },
    {
      title: i18n.gettext('Tasas especiales según la facturación.'),
    },
    {
      title: i18n.gettext('Sin alquiler ni tasa de adhesión.'),
    },
    {
      title: i18n.gettext('Cuenta digital gratuita.'),
    },
  ];

  const imageComponent = (
    <div className="point-benefits-wrapper__container-image">
      <Image
        alt="Diferentes modelos de maquininhas do Mercado Pago. Da esquerda para direita: Point Mini NFC 1, Point Tap, Point Pro 2 e Point Smart."
        src={`${imageName}.webp`}
        srcFallback={`${imageName}.png`}
        title="modelos-de-maquininhas-point"
      />
    </div>
  );

  return (
    <div className="point-benefits-wrapper">
      <Background isMobile={isMobile} />
      <div className="point-benefits-wrapper__container">
        {!isMobile && imageComponent}
        <div>
          <CardDivider />
          <Title component="h2" size="l" type="title">
            {i18n.gettext('El Point es la opción ideal para revender')}
          </Title>
          {isMobile && imageComponent}
          <div>
            {items.map((item) => (
              <div key={item.title} className="point-benefits-wrapper__container-item">
                <FeedbackPositive24 color="#009EE3" />
                <Text>{item.title}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PointBenefits.propTypes = {
  isMobile: PropTypes.bool,
};

module.exports = PointBenefits;
