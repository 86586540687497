// i18nLocalesOverride:['pt-BR']

const React = require('react');

const { useI18n } = require('nordic/i18n');
const { useLandingContext } = require('@context/landing-context');

const Fab = require('@components/fab');

const Benefits = require('./components/benefits');
const Faqs = require('./components/faqs');
const Footer = require('./components/footer');
const HeaderFixed = require('./components/header-fixed');
const Help = require('./components/help');
const Hero = require('./components/hero');
const Incentives = require('./components/incentives');
const Levels = require('./components/levels');
const PaymentMethod = require('./components/payment-method');
const PointBenefits = require('./components/point-benefits');
const Steps = require('./components/steps');
const Tyc = require('./components/tyc');

const LandingMlb = () => {
  const { devices, isMobile, isWebview, prizesConfigs, siteId, traficOrigin, userData } =
    useLandingContext();

  const { i18n } = useI18n();

  const whatsappMessage = encodeURIComponent(
    i18n.gettext('¡Hola! Me gustaría obtener más información sobre el Programa de Revendedores.'),
  );

  return (
    <div>
      <HeaderFixed isMobile={isMobile} isReseller={userData?.isReseller} />
      <Hero
        isMobile={isMobile}
        isWebview={isWebview}
        traficOrigin={traficOrigin}
        userData={userData}
      />
      <Benefits isMobile={isMobile} />
      <Incentives prizesConfigs={prizesConfigs} />
      <Levels isMobile={isMobile} prizesConfigs={prizesConfigs} />
      <Steps isMobile={isMobile} />
      <PointBenefits isMobile={isMobile} />
      <PaymentMethod />
      <Faqs devices={devices} prizesConfigs={prizesConfigs} />
      <Help />
      <Footer />
      <Tyc />
      <Fab
        isMobile={isMobile}
        siteId={siteId}
        title={i18n.gettext('Resuelve tus dudas')}
        url={`https://api.whatsapp.com/send/?phone=5511966192726&text=${whatsappMessage}&app_absent=0`}
      />
    </div>
  );
};

module.exports = LandingMlb;
