// i18nLocalesOverride:['es-CL']

const React = require('react');

const { useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { useLandingContext } = require('@context/landing-context');
const { useRebrandContext } = require('@context/rebrand-context');

const { Text, Title } = require('@andes/typography');

const Accordion = require('../accordion');
const SegmentedControl = require('../segmented-control');

const GROUP_ID = require('@domain/constants/group-id');

const BASE_CLASS_NAME = 'levels';
const COMPONENT_ID = 'levels';

const Levels = () => {
  const { i18n } = useI18n();

  const { assetsPath, isInRebrand } = useRebrandContext();
  const { isMobile, prizesSummary } = useLandingContext();

  const [levelSummary, setLevelSummary] = useState(prizesSummary?.[0]);

  const imageNameByLevel = {
    [GROUP_ID.MLC.EMPRENDEDOR_WITH_PURCHASES]: isMobile
      ? 'level-emprendedor-mobile'
      : 'level-emprendedor-desktop',
    [GROUP_ID.MLC.PROFESIONAL]: isMobile ? 'level-profesional-mobile' : 'level-profesional-desktop',
    [GROUP_ID.MLC.EXPERTO]: isMobile ? 'level-experto-mobile' : 'level-experto-desktop',
  };

  const levels =
    prizesSummary?.map((item) => ({
      label: item.groupName,
      value: item.groupId,
    })) || [];

  const handleLevelChange = (levelId) => {
    const selectedLevel = prizesSummary.find((item) => item.groupId === levelId);

    setLevelSummary(selectedLevel);
  };

  const renderPrizeDetails = () => {
    const hasActivationEarnings = levelSummary.prizes.activationEarnings !== '$ 0';
    const hasNewClientEarnings = levelSummary.prizes.newClientEarnings !== '$ 0';
    const hasSalesMarginEarnings = levelSummary.prizes.salesMarginEarnings !== '$ 0';

    if (!hasActivationEarnings && !hasNewClientEarnings && !hasSalesMarginEarnings) {
      return null;
    }

    return (
      <div className={`${BASE_CLASS_NAME}__prize-details`}>
        {!isMobile && (
          <Title component="h3" size="xl">
            {i18n.gettext('Resumen de las ganancias')}
          </Title>
        )}
        {hasSalesMarginEarnings && (
          <div>
            <Text className={`${BASE_CLASS_NAME}__prize-value`} component="span">
              {levelSummary.prizes.salesMarginEarnings}
              <Text size={isMobile ? 'xs' : 'm'}>*</Text>
            </Text>
            <Text color="secondary" size={isMobile ? 'xs' : 'm'}>
              {i18n.gettext('Por el margen de venta al revender el Point.')}
            </Text>
          </div>
        )}
        {hasNewClientEarnings && (
          <div>
            <Text className={`${BASE_CLASS_NAME}__prize-value`} component="span">
              {levelSummary.prizes.newClientEarnings}
              <Text size={isMobile ? 'xs' : 'm'}>*</Text>
            </Text>
            <Text color="secondary" size={isMobile ? 'xs' : 'm'}>
              {i18n.gettext('Por vender el Point a nuevos clientes.')}
            </Text>
          </div>
        )}
        {hasActivationEarnings && (
          <div data-show={hasActivationEarnings}>
            <Text className={`${BASE_CLASS_NAME}__prize-value`} component="span">
              {levelSummary.prizes.activationEarnings}
              <Text size={isMobile ? 'xs' : 'm'}>*</Text>
            </Text>
            <Text color="secondary" size={isMobile ? 'xs' : 'm'}>
              {i18n.gettext('Por lograr un mínimo de cobros al mes con el Point.')}
            </Text>
          </div>
        )}
      </div>
    );
  };

  if (!prizesSummary?.length) {
    return null;
  }

  return (
    <div className={BASE_CLASS_NAME} id={COMPONENT_ID}>
      <Title component="h1" size="l">
        {i18n.gettext('Entre más creces, más ganas')}
      </Title>
      <div className={`${BASE_CLASS_NAME}__details`}>
        <div className={`${BASE_CLASS_NAME}__container`}>
          <div
            className={`${BASE_CLASS_NAME}__chart ${BASE_CLASS_NAME}__chart${
              isInRebrand ? '' : '--blue'
            }`}
          >
            <div className={`${BASE_CLASS_NAME}__chart-details`}>
              <Text color="inverted" size="s" weight="regular">
                {i18n.gettext('Selecciona un nivel del programa y simula tus ganancias')}
              </Text>
              <SegmentedControl options={levels} onChange={handleLevelChange} />
              <Title color="inverted" component="h2" size="m">
                {i18n.gettext('Gana hasta {0}', levelSummary.prizes.totalEarnings)}
                <Text color="inverted" size="s">
                  *
                </Text>
              </Title>
              <Text color="inverted" component="h3" size="s" weight="regular">
                {i18n.gettext('Invirtiendo {0}', levelSummary.prizes.investment)}
              </Text>
            </div>
            <Image
              src={`${assetsPath}/${imageNameByLevel[levelSummary.groupId]}.webp`}
              srcFallback={`${assetsPath}/${imageNameByLevel[levelSummary.groupId]}.png`}
            />
          </div>
          {isMobile ? (
            <Accordion open title={i18n.gettext('Resumen de las ganancias')}>
              {renderPrizeDetails()}
            </Accordion>
          ) : (
            renderPrizeDetails()
          )}
        </div>
      </div>
      <Text className={`${BASE_CLASS_NAME}__footer`} color="secondary" size={isMobile ? 'xs' : 's'}>
        {i18n.gettext(
          '*La ganancia tiene en cuenta el margen de venta y todos los premios al comprar {0} {1}.',
          levelSummary.prizes.mostValuableDeviceMinBundle,
          levelSummary.prizes.mostValuableDeviceModel,
        )}
      </Text>
    </div>
  );
};

module.exports = Levels;
