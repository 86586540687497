const React = require('react');
const PropTypes = require('prop-types');

const { createContext, useContext } = React;

const RebrandContext = createContext();

export const useRebrandContext = () => {
  return useContext(RebrandContext);
};

export const RebrandProvider = ({ rebrand, children }) => {
  return <RebrandContext.Provider value={rebrand}>{children}</RebrandContext.Provider>;
};

RebrandProvider.propTypes = {
  rebrand: PropTypes.shape({
    isInRebrand: PropTypes.bool,
    colors: PropTypes.shape({
      ANDES_ACCENT_COLOR: PropTypes.string,
      ANDES_TEXT_COLOR_PRIMARY: PropTypes.string,
      CUSTOM_BRAND_COLOR: PropTypes.string,
      ANDES_TEXT_BRAND: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
};
