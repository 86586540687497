// i18nLocalesOverride:['es-CL']

const React = require('react');

const AboutProgram = require('./components/about-program');
const Advantages = require('./components/advantages');
const Faqs = require('./components/faqs');
const Footer = require('./components/footer');
const HeaderFixed = require('./components/header-fixed');
const Hero = require('./components/hero');
const Levels = require('./components/levels');
const PointInfo = require('./components/point-info');
const QuickAccess = require('./components/quick-access');
const Video = require('./components/video');

const BASE_CLASS_NAME = 'landing-mlc';

const LandingMlc = () => {
  return (
    <div className={BASE_CLASS_NAME}>
      <HeaderFixed />
      <Hero />
      <div className={`${BASE_CLASS_NAME}__container`}>
        <QuickAccess />
        <AboutProgram />
        <Levels />
        <PointInfo />
        <Video />
        <Advantages />
        <Faqs />
        <Footer />
      </div>
    </div>
  );
};

module.exports = LandingMlc;
